import React from "react";

import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../../../components/Forms/ErrorMessage";
import LoadingButton from "../../../../components/LoadingButton";

const RegistroForm = ({
  onLogin = () => {},
  formError = null,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Form onSubmit={handleSubmit(onLogin)}>
      <h3 className="mb-5 text-center">INICIAR SESIÓN</h3>
      <FormGroup>
        <FormLabel>E-mail</FormLabel>
        <FormControl
          {...register("email", {
            required: "Este campo es requerido",
          })}
          type="email"
        ></FormControl>
        <ErrorMessage errors={errors} name="email" />
      </FormGroup>
      <FormGroup>
        <FormLabel>Contraseña</FormLabel>
        <FormControl
          type="password"
          {...register("password", {
            required: "La contraseña es requerida",
          })}
        ></FormControl>
        <ErrorMessage errors={errors} name="password" />
      </FormGroup>
      <LoadingButton
        nativeType="submit"
        loading={loading}
        type="success"
        block
        size="lg"
      >
        INICIAR
      </LoadingButton>
      {formError && (
        <Form.Text className="text-danger mt-3">
          <i className="fas fa-exclamation-triangle mr-1"></i> {formError}
        </Form.Text>
      )}
    </Form>
  );
};

export default RegistroForm;
