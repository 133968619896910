/**
 *
 * @param {string} search
 */
export const queryParams = (search) => {
  const searchParams = new URLSearchParams(search);

  const values = {};

  for (const [key, value] of searchParams.entries()) {
    values[key] = value;
  }

  return values;
};
