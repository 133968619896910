import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FirebaseService } from "../../../services/firebase";
import RegistroForm from "./components/RegistroForm";
import IniciarForm from "./components/IniciarForm";
import { ClienteService } from "../../../services/ClienteService";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../context/AuthContext";
import { logo_google } from "../../../constants/images";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../../components/Forms/ErrorMessage";
import { createNotification } from "../../../libs/utils";
import LoadingButton from "../../../components/LoadingButton";

function Login() {
  const [loginError, setLoginError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [cuentaFB, setCuentaFB] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const history = useHistory();
  const { usuario, authenticated } = useContext(Auth);

  useEffect(() => {
    if (usuario) {
      history.push("/cuenta");
    }
  }, [usuario, history]);

  useEffect(() => {
    if (authenticated) {
      history.push("/cuenta");
    }
  }, [authenticated, history]);

  const redirectAfterLogin = () => {
    let url = "";
    localStorage.setItem("isAuth", true);
    if (history.location.state && history.location.state.carrito) {
      url = "/carrito";
    } else {
      url = "/cuenta";
    }

    window.location.href = window.location.origin + url;
  };

  const login = async ({ email, password }) => {
    try {
      setLoadingLogin(true);
      await FirebaseService.signInWithEmailAndPassword(email, password);
      setLoginError(null);
      redirectAfterLogin();
    } catch (err) {
      if (["auth/wrong-password", "auth/user-not-found"].includes(err.code)) {
        setLoginError("El correo electrónico o la contraseña son incorrectos");
      } else {
        setLoginError("Error al iniciar sesión, por favor intente de nuevo");
      }
    } finally {
      setLoadingLogin(false);
    }
  };

  const registerWithEmail = async (data) => {
    try {
      setLoadingRegister(true);
      const { email, password, nombre, apellido, telefono } = data;

      let credencials;

      if (!cuentaFB) {
        credencials = await FirebaseService.createUserWithEmailAndPassword(
          email,
          password
        );

        setCuentaFB(credencials);
      } else {
        credencials = cuentaFB;
      }

      await ClienteService.register({
        nombre,
        apellido,
        email,
        telefono,
        fb_uid: credencials.user.uid,
        account_type: "email",
      });

      setRegisterError(null);
      history.push("/cuenta");
    } catch (err) {
      if (["auth/email-already-in-use"].includes(err.code)) {
        setRegisterError("El correo electrónico ya se encuentra registrado.");
      } else {
        setRegisterError(
          "Error al registrar la cuenta, por favor intente de nuevo"
        );
      }
    } finally {
      setLoadingRegister(false);
    }
  };

  return (
    <Container className="my-5">
      <div className="d-none d-md-block">
        <DesktopLayout
          onLogin={login}
          loginError={loginError}
          loadingLogin={loadingLogin}
          onResetPassword={() => setShowResetPassword(true)}
          afterLogin={redirectAfterLogin}
          onRegister={registerWithEmail}
          registerError={registerError}
          loadingRegister={loadingRegister}
        />
      </div>

      <div className="d-block d-md-none">
        <MobileLayout
          onLogin={login}
          loginError={loginError}
          loadingLogin={loadingLogin}
          onResetPassword={() => setShowResetPassword(true)}
          afterLogin={redirectAfterLogin}
          onRegister={registerWithEmail}
          registerError={registerError}
          loadingRegister={loadingRegister}
        />
      </div>

      <ModalRestorePassword
        show={showResetPassword}
        onClose={() => setShowResetPassword(false)}
      />
    </Container>
  );
}

const DesktopLayout = ({
  onLogin,
  loginError,
  loadingLogin,
  onRegister,
  registerError,
  loadingRegister,
  onResetPassword = () => {},
  afterLogin = () => {},
}) => {
  return (
    <Row>
      <Col md={5}>
        <div className="d-none d-md-block" style={{ marginTop: "21%" }}></div>
        <IniciarForm
          onLogin={onLogin}
          formError={loginError}
          loading={loadingLogin}
        />
        <div className="text-center">
          <Button
            onClick={() => onResetPassword(true)}
            className="mt-3"
            variant="link"
          >
            ¿Haz olvidado tu contraseña?
          </Button>
        </div>
        <GoogleButton handler={afterLogin} texto="Iniciar sesión con Google" />
      </Col>

      <Col md={{ span: 1, offset: 1 }}>
        <div
          style={{
            borderLeft: "2px solid lightgrey",
            height: "900px",
            filter: "blur(.1px)",
            borderImage: "linear-gradient(white, lightgrey, white) 10 stretch",
          }}
        ></div>
      </Col>
      <Col md={5}>
        <ButtonContinuar />

        <RegistroForm
          onRegister={onRegister}
          formError={registerError}
          loading={loadingRegister}
        />
        <GoogleButton handler={afterLogin} texto="Registrarse con Google" />
      </Col>
    </Row>
  );
};

const MobileLayout = ({
  onLogin,
  loginError,
  loadingLogin,
  onRegister,
  registerError,
  loadingRegister,
  onResetPassword = () => {},
  afterLogin = () => {},
}) => {
  const [showRegister, setShowRegister] = useState(false);

  return (
    <div>
      <ButtonContinuar />
      {!showRegister && (
        <>
          <IniciarForm
            onLogin={onLogin}
            formError={loginError}
            loading={loadingLogin}
          />
          <div className="text-center">
            <Button
              onClick={() => onResetPassword(true)}
              className="mt-3"
              variant="link"
            >
              ¿Haz olvidado tu contraseña?
            </Button>
            <Button
              onClick={() => setShowRegister(true)}
              className="mt-3"
              variant="link"
            >
              Registrar una cuenta nueva
            </Button>
            <div className="mb-5">
              <GoogleButton
                handler={afterLogin}
                texto="Iniciar sesión con Google"
              />
            </div>
          </div>
        </>
      )}

      {showRegister && (
        <>
          <RegistroForm
            onRegister={onRegister}
            formError={registerError}
            loading={loadingRegister}
          />
          <GoogleButton handler={afterLogin} texto="Registrarse con Google" />
          <div className="text-center mt-1">
            <Button onClick={() => setShowRegister(false)} variant="link">
              Ir a iniciar sesión
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const GoogleButton = ({ texto, handler = () => {} }) => {
  const handleLogin = async () => {
    try {
      const crendentials = await FirebaseService.GoogleAuth();

      handler(crendentials);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      className="my-4 border border-default bg-white p-3 p-md-3"
      onClick={handleLogin}
      variant="light"
      block
      size="lg"
    >
      <img
        width="32px"
        className="mr-3 mr-md-5"
        src={logo_google}
        alt="Logo google"
      />
      <span className="">{texto}</span>
    </Button>
  );
};

const ModalRestorePassword = ({ show = false, onClose = () => {} }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const handleResetPassword = async ({ email }) => {
    try {
      setLoading(true);
      await FirebaseService.resetPassword(email);
      createNotification(
        "success",
        "Se enviado un mesaje a su correo electrónico",
        "Restablecer contraseña enviado"
      );
      onClose();
    } catch (err) {
      createNotification("error", "Error al restaurar la contraseña");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Restablecer contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleResetPassword)}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              {...register("email", {
                required: "El email es requerido",
              })}
            />
            <Form.Text>
              Ingrese el correo electrónico de su cuenta actual
            </Form.Text>
            <ErrorMessage name="email" errors={errors} />
          </Form.Group>
          <div className="text-right mt-5">
            <Button variant="light border border-dark mr-2" onClick={onClose}>
              Cerrar
            </Button>
            <LoadingButton
              customClass="btn-success"
              loading={loading}
              nativeType="submit"
            >
              Restablecer
            </LoadingButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const ButtonContinuar = () => {
  const history = useHistory();
  return (
    <Button
      block
      className="mb-5 border border-grey text-uppercase bg-white"
      variant="light"
      onClick={() => history.push("/carrito")}
    >
      Comprar sin iniciar sesión
    </Button>
  );
};

export default Login;
