import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Libs
import ReactGA from "react-ga";
// Contants
import { AnalyticsConfig } from "../../constants/analytics.config";
import Route from "./Route";

const PublicRoute = ({
  component: Component,
  title = "",
  registerView = true,
  ...rest
}) => {
  const location = useLocation();

  useEffect(() => {
    if (!window.GA_INITIALIZED && process.env.NODE_ENV === "production") {
      ReactGA.initialize(AnalyticsConfig.trackingId);
      window.GA_INITIALIZED = true;
      console.log("Inicializar GA");
    }

    if (registerView) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, registerView]);

  return <Route title={title} Component={Component} {...rest} />;
};

export default PublicRoute;
