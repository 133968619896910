import { HTTP } from "./http";

const defaultParams = {
  limit: 10,
};

export const ProductoService = {
  getAll(params = {}) {
    return HTTP.get(`producto`, {
      params: {
        ...defaultParams,
        ...params,
      },
    });
  },
  get(id, text_id) {
    return HTTP.get(`producto/${id}/${text_id}`);
  },
  buscar(params = {}) {
    return HTTP.get("/producto/buscar", {
      params,
    });
  },
  promociones() {
    return HTTP.get("/producto/promocion");
  },
  ofertas() {
    return HTTP.get("/producto/ofertas");
  },
};
