export const tiendas = [
  {
    id: "centro",
    sucursal: "Bee's Centro",
    coords: {
      lat: 29.081732,
      lng: -110.956644,
    },
    domicilio: "Av. Sonora esquina con  Yañez ",
    colonia: "Centro",
    ciudad: "Hermosillo",
  },
  {
    id: "estadio",
    sucursal: "Bee's Estadio Héctor Espino",
    coords: {
      lat: 29.098089,
      lng: -110.989557,
    },
    domicilio: "Soyopa #83 esquina con José Healy",
    colonia: "Pimentel",
    ciudad: "Hermosillo",
  },
];
