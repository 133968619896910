import { ErrorMessage } from "@hookform/error-message";
import React from "react";

const FormErrorMessage = (props) => {
  return (
    <ErrorMessage
      {...props}
      render={({ message }) => (
        <span style={{ fontSize: 11 }} className="text-danger">
          <i className="fas fa-exclamation-triangle"></i> {message}
        </span>
      )}
    />
  );
};

export default FormErrorMessage;
