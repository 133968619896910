import React, { useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import InputForm from "./Input";
const Buscador = ({
  defaultValue = "",
  onChange = () => {},
  buscar = () => {},
}) => {
  const [value, setValue] = useState();

  const handleOnChange = (e) => {
    const value = e.target.value;

    setValue(value);
    onChange(value);
  };

  return (
    <InputGroup className="mb-2">
      <InputForm
        defaultValue={defaultValue}
        className="border-color-honey"
        onChange={handleOnChange}
      />
      <InputGroup.Prepend onClick={() => buscar(value)}>
        <Button
          variant="warning"
          className="bg-honey border-color-honey"
          style={{ cursor: "pointer" }}
          size="sm"
        >
          <span className="search-icon"> </span>
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  );
};

export default Buscador;
