export const MobileLayout = ({ children, style = {} }) => (
  <div style={style} className="d-block d-md-none">
    {children}
  </div>
);

export const TabletLayout = ({ children, style = {}, onlyTablet = true }) => (
  <div
    style={style}
    className={`d-none d-md-block ${onlyTablet && "d-lg-none"}`}
  >
    {children}
  </div>
);

export const DesktopLayout = ({ children, style = {} }) => (
  <div style={style} className="d-none d-lg-block">
    {children}
  </div>
);
