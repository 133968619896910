import React, { useEffect, useState } from "react";
// Constants
import { producto_imagen_default } from "../../../constants/images";
import { Button, Col, Row } from "react-bootstrap";
import { currency } from "../../../libs/currency";

const ProductoInfo = ({
  producto,
  productoId,
  onCompartir,
  carrito,
  agregar,
  restar,
  agregarProducto,
}) => {
  const existeEnCarrito = carrito.find(
    (producto) => producto.id === productoId
  );

  useEffect(() => {
    if (existeEnCarrito) {
      setCantidad(existeEnCarrito.cantidad);
    }
  }, [carrito, productoId, existeEnCarrito]);

  const [cantidad, setCantidad] = useState(1);

  const handleAgregar = () => {
    if (cantidad + 1 <= producto.existencia) {
      setCantidad(cantidad + 1);
      if (existeEnCarrito) {
        agregar(productoId);
      }
    }
  };

  const handleRestar = () => {
    setCantidad(cantidad - 1);
    if (existeEnCarrito) {
      restar(productoId);
    }
  };

  const handleAgregarProducto = () => {
    producto.cantidad = cantidad;
    agregarProducto(producto);
  };

  const getDescuento = () => {
    const { precio_descuento, precio1 } = producto;

    const descuento = Math.round(100 - (precio_descuento * 100) / precio1);

    return descuento;
  };

  const componentes = React.useMemo(() => {
    return producto ? producto.componentes : [];
  }, [producto]);

  return (
    <Row>
      <Col md={7} className="mb-4 mb-md-0">
        <div className="product-gallery mx-1">
          <div className="mb-0">
            <figure className="view overlay rounded z-depth-1 main-img">
              <img
                style={{ maxWidth: 500 }}
                src={producto.url || producto_imagen_default}
                className="w-100 z-depth-1"
                alt=""
              />
            </figure>
          </div>
        </div>
      </Col>
      <Col md={4}>
        <div
          className="d-flex align-items-start flex-column"
          style={{ height: 600 }}
        >
          <div className="mb-auto">
            <h5 className="titulo-producto">{producto.descripcion}</h5>
            <p>
              <span className="mr-5">
                {producto.tiene_descuento ? (
                  <>
                    <span className="text-muted">
                      <s>{currency(producto.precio)}</s>
                    </span>
                    <br />
                    <strong className="text-success">
                      {currency(producto.precio_descuento)}
                    </strong>
                    <span className="ml-2 badge badge-success">
                      {getDescuento()}%
                    </span>
                  </>
                ) : (
                  <strong>{currency(producto.precio)}</strong>
                )}
              </span>
            </p>
            <small className="text-muted">
              <span className="font-weight-bold">{producto.existencia}</span>{" "}
              disponibles
            </small>
            <hr />
            <Row className="mb-2 row">
              <Col className="col">
                <div className="cantidad-input">
                  <span className="mr-2">
                    <button
                      disabled={cantidad <= 1}
                      className="btn btn-default"
                      data-dir="dwn"
                      onClick={() => handleRestar()}
                    >
                      <span className="fas fa-minus"></span>
                    </button>
                  </span>
                  {cantidad}
                  <span className="ml-2">
                    <button
                      className="btn btn-default"
                      data-dir="up"
                      onClick={() => handleAgregar()}
                    >
                      <span className="fas fa-plus"></span>
                    </button>
                  </span>
                </div>
              </Col>
            </Row>
            <Button
              block
              variant="white"
              className="border border-success text-success"
              onClick={() => handleAgregarProducto()}
            >
              AÑADIR AL CARRITO
            </Button>
            <div className="mt-4">
              <h6
                className="text-success font-weight-bold"
                style={{ fontSize: 17 }}
              >
                Componentes
              </h6>
              <hr />
              <div style={{ maxHeight: 500, overflowY: "auto" }}>
                {componentes.map((componente, index) => (
                  <p className="text-muted" key={index}>
                    - {componente}
                  </p>
                ))}
              </div>
              {componentes.length === 0 && (
                <span className="text-muted">
                  <i className="fas fa-info-circle"></i> No se ha especificado
                  este apartado
                </span>
              )}
            </div>
          </div>

          <div style={{ cursor: "pointer" }} onClick={onCompartir}>
            <div className="text-muted text-uppercase" style={{ fontSize: 22 }}>
              <i className="fas fa-share-alt mr-2"></i>
              Compartir
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProductoInfo;
