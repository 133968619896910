import React from "react";
import ReactPaginate from "react-paginate";

import "./Paginacion.scss";

const Paginacion = ({
  total_paginas,
  setPagina,
  pageRangeDisplayed = 5,
  marginPagesDisplayed = 2,
  previousLabel = "Anterior",
  nextLabel = "Siguiente",
  currentPage = 1,
}) => {
  return (
    <div className="row">
      <div className="col">
        <ReactPaginate
          forcePage={currentPage - 1}
          disableInitialCallback={true}
          previousLabel={previousLabel}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          nextLabel={nextLabel}
          breakLabel={<span className="separator">...</span>}
          breakClassName={"separator"}
          pageCount={total_paginas}
          marginPagesDisplayed={marginPagesDisplayed}
          pageRangeDisplayed={pageRangeDisplayed}
          onPageChange={(ev) => setPagina(ev.selected + 1)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default Paginacion;
