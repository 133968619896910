import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../context/AuthContext";
import { Store } from "../../../context/RootContext";
import { createNotification } from "../../../libs/utils";
import { ClienteService } from "../../../services/ClienteService";

import "./Cuenta.scss";

const Cuenta = () => {
  const { userData } = useContext(Auth);
  const { carrito } = useContext(Store);
  const history = useHistory();
  const [cuenta, setCuenta] = useState({
    comentarios: [],
    me_gustas: [],
    puntos: 0,
    ordenes: [],
  });

  const ordenesProcesando = useMemo(() => {
    return cuenta.ordenes.filter((orden) => {
      return orden.estatus === "Atendido";
    });
  }, [cuenta.ordenes]);

  const ordenesEnviadas = useMemo(() => {
    return cuenta.ordenes.filter((orden) => {
      return orden.estatus === "Enviado";
    });
  }, [cuenta.ordenes]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const {
          data: { comentarios, likes, puntos, ordenes },
        } = await ClienteService.cuenta();

        setCuenta({
          comentarios,
          likes,
          puntos,
          ordenes,
        });
      } catch (err) {
        createNotification("error", "Error al obtener la información");
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return (
      <h3 className="text-center mt-5">
        <i className="fas fa-spinner fa-pulse"></i> Cargando datos
      </h3>
    );
  }

  return (
    <div className="my-5 text-center">
      <h2 className="text-muted" style={{ fontWeight: 600 }}>
        Hola, {userData.nombre}
      </h2>
      {/* TODO implementar puntos y likes */}
      <Card className="mt-5">
        <Card.Body>
          <Button
            variant="text"
            className="cuenta__links"
            title="Proximamente"
            disabled
          >
            {cuenta.puntos} <br /> <b>Puntos</b>
          </Button>
          <Button
            variant="text"
            className="cuenta__links"
            onClick={() => history.push("/cuenta/comentarios")}
          >
            {cuenta.comentarios.length} <br /> <b>Comentarios</b>
          </Button>
          <Button
            variant="text"
            className="cuenta__links"
            onClick={() => history.push("/cuenta/likes")}
          >
            {cuenta.likes} <br /> <b>Me gusta</b>
          </Button>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Body>
          <Button
            onClick={() => history.push("/carrito")}
            variant="text"
            className="cuenta__links"
          >
            {carrito.length} <br /> <b>Carrito</b>
          </Button>
          <Button
            variant="text"
            className="cuenta__links"
            onClick={() => history.push("/ordenes/procesando")}
          >
            {ordenesProcesando.length} <br /> <b>Procesando</b>
          </Button>
          <Button
            variant="text"
            className="cuenta__links"
            onClick={() => history.push("/ordenes/enviados")}
          >
            {ordenesEnviadas.length} <br /> <b>Enviados</b>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Cuenta;
