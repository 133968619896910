import { HTTP } from "./http";

export const OrdenService = {
  create(datos_orden) {
    return HTTP.post("orden", datos_orden);
  },
  getAll(params = {}) {
    return HTTP.get("orden", {
      params,
    });
  },
  get(id) {
    return HTTP.get(`orden/${id}/detail`);
  },
  feedback(id, data) {
    return HTTP.put(`orden/${id}/feedback`, data);
  },
};
