import { Button, Modal, ModalBody } from "react-bootstrap";

const ModalAlertCuenta = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <ModalBody>
        <div className="text-right">
          <Button onClick={onClose} variant="text">
            <i className="fas fa-times text-muted"></i>
          </Button>
        </div>
        <div className="p-2">
          <h3 className="text-center text-bolder mb-4 text-muted">
            ¿Ya te registraste?
          </h3>
          <p className="font-weight-light">
            Inicia sesión y tendrás oportunidad de acumular puntos, darnos tu
            opinión, recibir cupones, promociones, y más.
          </p>
          <div className="text-center">
            <Button variant="light" size="lg" block>
              Iniciar sesión
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAlertCuenta;
