import { HTTP } from "./http";

export const CodigoService = {
  apply(codigo, carrito) {
    return HTTP.post("/cupon/apply", {
      codigo,
      carrito,
    });
  },
};
