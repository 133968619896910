import Swal from "sweetalert2";

export const createNotification = (type, message, title = null) => {
  const notification = {
    icon: type,
    title: title ? title : message,
    text: title && message,
  };

  Toast.fire(notification);
};

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
