import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { OrdenService } from "../../services/OrdenService";
import CalificarOrden from "./components/CalificarOrden";
import InfoCalificarComentar from "./components/InfoCalificarComentar";

const Feedback = () => {
  const [showCalificar, setShowCalificar] = useState(false);
  const { id } = useParams();
  const [orden, setOrden] = useState(null);
  const [rates, setRates] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await OrdenService.get(id);

        setOrden(data.orden);
        setRates(data.rates);
      } catch (err) {
        if (
          (err.response && err.response.data.error === "not_access") ||
          err.response.status === 401
        ) {
          history.push("/cuenta");
        }
      }
    };

    init();
  }, [id, history]);

  if (!orden) {
    return (
      <h3 className="text-center mt-5">
        <i className="fas fa-spinner fa-pulse"></i> Cargando información
      </h3>
    );
  }

  return (
    <Container className="my-5">
      {!showCalificar ? (
        <InfoCalificarComentar onCalificar={() => setShowCalificar(true)} />
      ) : (
        <CalificarOrden orden={orden} rates={rates} />
      )}
    </Container>
  );
};

export default Feedback;
