import { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { dateFormat } from "../../libs/dateFormat";
import { createNotification } from "../../libs/utils";
import { RateService } from "../../services/RateService";
import Rate from "../Rate";

const Comentario = ({
  comentario,
  authenticated,
  userData,
  readOnly = false,
  count = 0,
  showRate = true,
}) => {
  const [like, setLike] = useState(false);
  const [alreadyLike, setAlreadyLike] = useState(false);

  useEffect(() => {
    if (authenticated && !readOnly) {
      const like = comentario.likes.find(
        (like) => like.cliente_id === userData.id
      );

      if (like) {
        setLike(true);
        setAlreadyLike(true);
      }
    }
  }, [authenticated, userData, comentario, readOnly]);

  const history = useHistory();

  const { cliente, rate, created_at, comentario: texto, id } = comentario;
  const fechaFormato = dateFormat(created_at, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const canLike = useMemo(() => {
    if (readOnly) {
      return false;
    }

    if (!authenticated) {
      return true;
    }

    if (userData.id === comentario.cliente_id) {
      return false;
    } else {
      return true;
    }
  }, [authenticated, comentario.cliente_id, userData, readOnly]);

  const requestSession = async () => {
    const value = await Swal.fire({
      icon: "info",
      title: "Cuenta requerida",
      text: "Para dar me gusta a este comentario es necesaria una cuenta.",
      confirmButtonText: "Crear cuenta",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "greylight",
      showCancelButton: true,
      customClass: {
        cancelButton: "border border-dark bg-white text-dark",
      },
    });

    if (value.isConfirmed) {
      history.push("/login");
    }
  };

  const toggleLike = async () => {
    try {
      if (!authenticated) {
        await requestSession();
      } else {
        await RateService.like(id, !like);
        setLike(!like);
      }
    } catch (err) {
      createNotification("error", "Error al dar me gusta");
    }
  };

  const countLikes = useMemo(() => {
    if (readOnly) {
      return count;
    }

    const { likes } = comentario;

    if ((!alreadyLike && !like) || (alreadyLike && like)) {
      return likes.length;
    } else if (alreadyLike && !like) {
      return likes.length - 1;
    } else if (!alreadyLike && like) {
      return likes.length + 1;
    }
  }, [alreadyLike, like, comentario, readOnly, count]);

  return (
    <div
      style={{
        borderBottom: "1px",
        borderStyle: "dashed",
        borderRight: 0,
        borderLeft: 0,
        borderTop: 0,
        borderColor: "darkgray",
      }}
    >
      <Row>
        <Col xs={6} md={2}>
          <span className="font-weight-bold align-self-center">
            {cliente.nombre} {cliente.apellido}
          </span>
        </Col>
        {showRate && (
          <Col>
            <Rate defaultValue={rate} disabled />
          </Col>
        )}
      </Row>
      <p className="mt-3">{texto}</p>
      <div className="d-flex">
        <p className="text-secondary mt-4 text-capitalize mr-auto">
          {fechaFormato}
        </p>
        <div className="align-self-center">
          <span
            className="font-weight-bold align-middle"
            title="Número de me gustas"
          >
            {countLikes}
          </span>
          <Button disabled={!canLike} onClick={toggleLike} variant="text">
            <i
              className={`${like ? "fas text-success" : "far"}  fa-thumbs-up`}
            ></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Comentario;
