import { useState, useCallback, useEffect } from "react";
import { createNotification } from "../libs/utils";
import { CategoriaService } from "../services/CategoriaService";
import { ProductoService } from "../services/ProductoService";

export const useProducto = (pagina, categoria, descripcion) => {
  const [productos, setProductos] = useState([]);
  const [paginas, setPaginas] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [buscando, setBuscando] = useState(false);
  const [ultimaDescripcion, setUltimaDescripcion] = useState("");

  const getProductos = useCallback(async (pagina, categoria) => {
    const params = {
      page: pagina,
    };

    if (categoria && categoria) {
      params.categoria = categoria;
    }

    const {
      data: { last_page, data: productos, total },
    } = await ProductoService.getAll(params);

    setProductos(productos);
    setPaginas(last_page);
    setTotal(total);
  }, []);

  const buscar = useCallback(async (descripcion, pagina, categoria) => {
    const params = {
      descripcion,
      page: pagina,
    };

    if (categoria) {
      params.categoria = categoria;
    }

    const {
      data: { total, last_page, data: productos },
    } = await ProductoService.buscar(params);

    const totalPaginas = total > 0 ? last_page : 0;

    setProductos(productos);
    setPaginas(totalPaginas);
    setTotal(total);
  }, []);

  const queryProductos = useCallback(async () => {
    try {
      setIsLoading(true);
      if (descripcion) {
        await buscar(descripcion, pagina, categoria);
        setBuscando(true);
        setUltimaDescripcion(descripcion);
      } else {
        await getProductos(pagina, categoria);
        setBuscando(false);
      }
    } catch (error) {
      createNotification("error", "Error al momento de obtener los productos");
    } finally {
      setIsLoading(false);
    }
  }, [categoria, pagina, descripcion, buscar, getProductos]);

  useEffect(() => {
    queryProductos();
  }, [queryProductos]);

  return {
    paginas,
    productos,
    total,
    isLoading,
    buscando,
    ultimaDescripcion,
  };
};

export const getCategorias = async () => {
  try {
    const { data } = await CategoriaService.getAll();
    return data;
  } catch (error) {
    console.log(error);
    createNotification("error", "Error al momento de consultar las categorias");
  }
};

export const getProductosOfertas = async () => {
  try {
    const { data } = await ProductoService.ofertas();
    return data;
  } catch (err) {
    createNotification("error", "Error al consultar promociones");
  }
};

export const getProductosPromocion = async () => {
  try {
    const { data } = await ProductoService.promociones();
    return data;
  } catch (err) {
    createNotification("error", "Error al consultar promociones");
  }
};
