import React from "react";

const LoadingButton = ({
  type,
  nativeType = "button",
  customClass = "",
  icon = "",
  loading = false,
  children = "Guardar",
  OnClick,
  disabled = false,
  block = false,
  size = "md",
  style = {},
}) => {
  const handleClick = () => {
    if (typeof OnClick === "function") {
      OnClick();
    }
  };

  return (
    <>
      <button
        style={style}
        className={`btn ${type && "btn-" + type}  ${customClass} ${
          block && "btn-block"
        } ${size ? "btn-" + size : "btn-md"}  `}
        disabled={loading || disabled || false}
        type={nativeType}
        onClick={handleClick}
      >
        {loading && <i className="fas fa-spinner fa-pulse"></i>}
        {!loading && icon && <i className={`fas ${icon}`}></i>}
        <span className="ml-2">{children}</span>
      </button>
    </>
  );
};

export default LoadingButton;
