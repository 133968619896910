import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { frase, imagen_home } from "../../constants/images";
// Components
import Promocion from "./Promocion/Promocion";
// Hooks
import { getProductosPromocion } from "../../hooks/ProductoHook";
// Context
import { Store } from "../../context/RootContext";
// Styles
import "./Landing.sass";
import { useQuery } from "react-query";

const Landing = () => {
  const { agregarProducto } = useContext(Store);

  const { data: promociones, isLoading } = useQuery(
    "promociones",
    getProductosPromocion,
    {
      staleTime: Infinity,
    }
  );

  const agregar = (producto) => {
    agregarProducto(
      {
        ...producto,
        cantidad: 1,
      },
      1
    );
  };

  return (
    <div className="landing">
      <div className="row ">
        <div className="col">
          <div className="row header-master">
            <div className="col-md-6">
              <div className="home_image_1">
                <img className="img-fluid" src={imagen_home} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home_image_1 mt-5">
                <img className="img-fluid mt-5" src={frase} alt="" />
              </div>
              <Link
                to="/tienda"
                className="btn btn-custom btn-outline-white mt-3"
              >
                IR A TIENDA
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row background-gray">
        <div className="col text-center mt-5 mb-5">
          <span className="home-icons delivery"></span>
          <p className="home-text-us">ENVIOS A TODA LA REPUBLICA</p>
        </div>
        <div className="col text-center mt-5 mb-5">
          <span className="home-icons shop"></span>
          <p className="home-text-us">SEGURIDAD DE COMPRA</p>
        </div>
        <div className="col text-center mt-5 mb-5">
          <span className="home-icons experience"></span>
          <p className="home-text-us">20 AÑOS DE EXPERIENCIA</p>
        </div>
      </div>

      {!isLoading && <Promocion productos={promociones} agregar={agregar} />}

      <div className="row align-items-center landing__history">
        <div className="col-md-5 landing__history-text">
          <h1 className="text-color landing__title">HISTORIA</h1>
          <p className="text-justify">
            Mieles de sonora nació en 1998 debido a la necesidad de poder
            subsistir de la familia del fundador el ingeniero José Manuel Pérez
            Marín el cual empezó debido a que fue rescindido de su trabajo en
            las telecomunicaciones y para poder subsistir a su familia decidió
            incorporarse al mercado apícola, empezó con una colmena que
            mantenía, esto debido a que a temprana edad obtuvo el conocimiento
            gracias a su padre debido a que ellos mantenían una colmena.
            Aprovechando diferentes cursos impartidos por organizaciones y
            privadas reforzó su conocimiento logrando así aprovechar al máximo
            el valor de las abejas. <br />
            Una vez incursionado en el mercado se informó y capacitó en las
            medidas adecuadas que se requerían para surgir correctamente en ese
            mercado, esto facilito los tramites y requerimientos para así lograr
            vender su mercado. <br />
            Con las distintas capacitaciones se decidió aprovechar el principal
            producto que es la miel de abeja logrando así desarrollar e
            implementar innovaciones para sacar nuevos productos para ofrecer a
            nuestros clientes, logrando esto se adquirió el propóleo el cual es
            conocido de muy alta calidad gracias a una investigación realizada
            por CIAD la cual subió y valor e incremento la marca de mieles de
            sonora. Logrando así la creación del primer jarabe derivado de la
            miel el cual es nuestro famoso PULMOMIEL, esto a su vez ayudo a
            desarrollar más productos derivados de la miel. Esto logró que la
            empresa estuviera presente en el mercado por más de 20 años,
            logrando que sea reconocida tanto como local como
            internacionalmente, estableciendo así la importancia de la
            producción sonorense, actualmente las colmenas se encuentran
            distribuidas a lo largo de la sierra y costa sonorense por lo cual
            adquiere que se logre la gran calidad conocida por nuestros
            clientes. Mieles de sonora es una empresa familiar la cual basa sus
            valores de trabajo como nuestra calidad, responsabilidad, ética y
            compromiso, unos de nuestros objetivos es establecer tanto
            nacionalmente como internacionalmente la importancia de la
            producción sonorense, logrando así un incremento en el mercado del
            estado.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <div className="row home_image_2"></div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
