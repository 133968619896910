import React, { Fragment, useMemo, useState } from "react";
import { Col, FormControl, Row, Form } from "react-bootstrap";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

import Rate from "../../../components/Rate";
import { OrdenService } from "../../../services/OrdenService";
import LoadingButton from "../../../components/LoadingButton";
import { createNotification } from "../../../libs/utils";
import { useHistory } from "react-router-dom";
import FormErrorMessage from "../../../components/FormErrorMessage";
import Swal from "sweetalert2";

const servicios = [
  {
    key: "productos_calificacion",
    label: "Productos",
  },
  {
    key: "servicio_calificacion",
    label: "Servicio",
  },
  {
    key: "atencion_calificacion",
    label: "Atención",
  },
];

const CalificarOrden = ({ orden }) => {
  const methods = useForm();

  const {
    formState: { errors },
  } = methods;
  const history = useHistory();

  const productos = useMemo(() => {
    return orden.detalle.map(({ producto, rate }) => {
      return {
        ...producto,
        rate,
      };
    });
  }, [orden]);

  const serviciosCalificar = useMemo(() => {
    return servicios.map((item) => {
      return {
        ...item,
        value: orden[item.key] || undefined,
      };
    });
  }, [orden]);

  const [loading, setLoading] = useState(false);

  const handleConfirm = (data) => {
    Swal.fire({
      title: "Confirmar respuestas",
      text: "Esta a punto confirmar las calificaciones y comentarios",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#319A46",
      cancelButtonColor: "light-gray",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar respuestas",
    }).then((result) => {
      if (result.isConfirmed) {
        sendFeedback(data);
      }
    });
  };

  const sendFeedback = async (data) => {
    try {
      setLoading(true);
      await OrdenService.feedback(orden.id, data);

      createNotification("success", "¡Gracias por calificar nuestro servicio!");

      history.push("/ordenes");
    } catch (err) {
      console.log(err);
      createNotification("error", "Error al calificar orden");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleConfirm)}>
        <section>
          <h5
            className="text-center text-success text-uppercase"
            style={{ fontWeight: 600 }}
          >
            Apoya a la comunidad con tus comentarios
          </h5>

          <div className="d-flex justify-content-center mt-4">
            <div>
              <h5 className="text-secondary" style={{ fontWeight: 600 }}>
                Califica nuestros servicios
              </h5>
              <Row className="mt-3" style={{ maxWidth: 400 }}>
                {serviciosCalificar.map((item) => (
                  <Fragment key={item.key}>
                    <Col sm={3} className="mr-3">
                      <h5 className="text-muted">{item.label}</h5>
                    </Col>
                    <Col sm={8}>
                      <RateInputForm
                        defaultValue={item.value}
                        name={`${item.key}`}
                        label={item.label}
                      />
                    </Col>
                    <Col sm={12}>
                      <FormErrorMessage errors={errors} name={item.key} />
                    </Col>
                  </Fragment>
                ))}
              </Row>
            </div>
          </div>
        </section>

        <hr />
        <section className="my-5">
          <h5 className="text-uppercase text-secondary text-center">
            Lista de productos
          </h5>

          {productos.map((producto, index) => (
            <ProductoOrdenCalificar
              key={producto.id}
              index={index}
              producto={producto}
            />
          ))}
        </section>

        <div className="mt-5 text-center">
          <LoadingButton
            loading={loading}
            nativeType="submit"
            customClass="p-1 font-weight-bolder"
            size="lg"
            type="success"
            style={{ width: 200, fontSize: 25 }}
          >
            Finalizar
          </LoadingButton>
        </div>
      </Form>
    </FormProvider>
  );
};

const ProductoOrdenCalificar = ({ producto, index }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  let comentario = "";
  let rate = undefined;

  if (producto.rate) {
    rate = producto.rate.rate;
    comentario = producto.rate.comentario || "";
  }

  const nameComentarioField = `productos.${index}.comment`;
  const nameRateField = `productos.${index}.rate`;
  const nameIdField = `productos.${index}.id`;

  return (
    <div className="d-flex justify-content-center my-5">
      <div style={{ maxWidth: 900 }}>
        <Row className="mt-5">
          <Col md={5}>
            <img width={200} src={producto.url} alt="imagen producto" />
          </Col>
          <Col md={7}>
            <p className="text-success">{producto.descripcion}</p>
          </Col>
        </Row>
        <Row className="mt-5 mb-1">
          <Col sm={4}>
            <p className="font-weight-bolder text-secondary">
              Valorar Producto
            </p>
          </Col>
          <Col sm={8}>
            <RateInputForm
              label={producto.descripcion}
              name={nameRateField}
              defaultValue={rate}
            />
          </Col>
          <Col sm={12}>
            <FormErrorMessage errors={errors} name={nameRateField} />
          </Col>
        </Row>
        <FormControl
          value={producto.id}
          hidden
          {...register(nameIdField, { required: true })}
        />
        <FormControl
          {...register(nameComentarioField, {
            minLength: "El comentario debe contener al menos 20 caracteres",
          })}
          defaultValue={comentario}
          as="textarea"
          rows="4"
          cols="50"
          placeholder="Dejanos tus comentarios para la comunidad Natural Bee's"
        />
        <FormErrorMessage errors={errors} name={nameComentarioField} />
      </div>
    </div>
  );
};

const RateInputForm = ({ name, label, defaultValue }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: `La calificación de ${label.toLowerCase()} es requerido`,
      }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => (
        <Rate
          onRate={onChange} // send value to hook form
          inputRef={ref}
          defaultValue={value}
        />
      )}
    />
  );
};

export default CalificarOrden;
