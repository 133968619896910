import React, { useContext, useEffect, useState } from "react";
// Components
import ListaProductos from "../../containers/ListaProductos";
import Paginacion from "../../components/Paginacion";
import { getCategorias, useProducto } from "../../hooks/ProductoHook";
import Categorias from "./components/Categorias";
// Context
import { Store } from "../../context/RootContext";
// Libs
import Buscador from "../../components/Forms/Buscador";

import "./Tienda.scss";
import { Col, Jumbotron, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { queryParams } from "../../libs/queryParams";

const Tienda = () => {
  const history = useHistory();
  const location = useLocation();

  const { page = 1, q = "", category = "" } = queryParams(location.search);

  const [pagina, setPagina] = useState(Number(page) || 1);
  const [categoria, setCategoria] = useState(category);
  const [descripcion, setDescripcion] = useState(q);

  useEffect(() => {
    setPagina(page);
    setDescripcion(q);
    setCategoria(category);
  }, [page, q, category]);

  const { paginas, productos, total, isLoading, buscando, ultimaDescripcion } =
    useProducto(pagina, categoria, descripcion);

  const { agregarProducto } = useContext(Store);

  const { data: categorias, isLoading: loadingCategorias } = useQuery(
    "categorias",
    getCategorias,
    {
      staleTime: Infinity,
    }
  );

  const agregar = (producto) => {
    producto.cantidad = 1;
    agregarProducto(producto, 1);
  };

  const handleBuscar = async (descripcion) => {
    if (descripcion) {
      handleQueryParams({ descripcion, pagina: 1 });
    }
  };

  const handleCambiarPagina = (pagina) => {
    window.scrollTo(0, 0);

    handleQueryParams({ pagina });
  };

  const onChangeBuscador = (descripcion) => {
    if (descripcion === "") {
      handleQueryParams({ descripcion: "", pagina: 1 });
    }
  };

  const onChangeCategoria = (categoria) => {
    handleQueryParams({ categoria, pagina: 1 });
  };

  const handleQueryParams = (params = {}) => {
    const searchParams = new URLSearchParams();

    const paginaValue = params.pagina || pagina;
    let descripcionValue = descripcion;
    let categoriaValue = categoria;

    if (params.descripcion !== undefined) {
      descripcionValue = params.descripcion;
    }

    if (params.categoria !== undefined) {
      categoriaValue = params.categoria;
    }

    if (descripcionValue) {
      searchParams.append("q", descripcionValue);
    }

    if (paginaValue) {
      searchParams.append("page", String(paginaValue));
    }

    if (categoriaValue) {
      searchParams.append("category", categoriaValue);
    }

    history.push({
      pathname: "/tienda",
      search: searchParams.toString(),
    });
  };

  return (
    <div className="container-fluid">
      <div className="mt-5 font-weight-light text-secondary text-center">
        <h2 style={{ fontSize: 26 }}>
          {buscando && (
            <>
              Resultados de:
              <span className="font-weight-bold ml-3">
                {ultimaDescripcion}{" "}
              </span>
            </>
          )}

          {categoria && !buscando && (
            <>
              Resultados de categoria:
              <span className="font-weight-bold ml-3">{categoria} </span>
            </>
          )}

          {!buscando && !categoria && (
            <span style={{ fontSize: 26 }}>Todos nuestros productos</span>
          )}
        </h2>
      </div>

      <Row className="mb-5">
        <Col md={3}>
          <>
            {!loadingCategorias && (
              <Categorias
                defaultValue={categoria}
                categoria={categoria}
                categorias={categorias}
                onSelected={(categoria) => onChangeCategoria(categoria)}
                onClear={() => onChangeCategoria("")}
              />
            )}
          </>
        </Col>
        <Col md={{ span: 4, offset: 5 }}>
          <div className="mt-4">
            <Buscador
              defaultValue={descripcion}
              onChange={onChangeBuscador}
              buscar={handleBuscar}
            />
          </div>
          <p>
            {productos.length} de {total} resultados
          </p>
        </Col>
      </Row>

      {productos.length > 0 && (
        <ListaProductos lista={productos} agregar={agregar} />
      )}

      {total > 1 && (
        <div className="d-flex justify-content-center mt-5">
          <div className="d-none d-md-block">
            <Paginacion
              currentPage={pagina}
              total_paginas={paginas}
              setPagina={handleCambiarPagina}
              pagina={pagina}
            />
          </div>
          <div className="d-sm-block d-md-none">
            {/* SM Devices */}
            <Paginacion
              currentPage={pagina}
              total_paginas={paginas}
              setPagina={handleCambiarPagina}
              pagina={pagina}
              previousLabel="<"
              nextLabel=">"
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
            />
          </div>
        </div>
      )}
      {total === 0 && !isLoading && (
        <Jumbotron className="bg-light">
          <h4 className="font-weight-bold text-center">
            <i className="fas fa-info-circle mr-2"></i>
            No se encontraron resultados
          </h4>
        </Jumbotron>
      )}

      {isLoading && (
        <Jumbotron className="bg-light">
          <h4 className="font-weight-bold text-center">
            <i className="fas fa-spinner fa-pulse mr-2"></i>
            Cargando resultados
          </h4>
        </Jumbotron>
      )}
    </div>
  );
};

export default Tienda;
