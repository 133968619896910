export const landing_routes = [
  {
    name: "Inicio",
    path: "/inicio",
  },
  {
    name: "Tienda",
    path: "/tienda",
  },
  {
    name: "Ofertas",
    path: "/ofertas",
  },
];
