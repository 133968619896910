import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import "./index.css";
import "./index.sass";
// Context
import * as serviceWorker from "./serviceWorker";
import RootContext from "./context/RootContext";
import AuthContext from "./context/AuthContext";
// Pages
import Landing from "./pages/Landing";
import Tienda from "./pages/Tienda";
import NavbarComp from "./containers/Navbar";
import Producto from "./pages/Producto";
import Carrito from "./pages/Carrito/Carrito";
import Ofertas from "./pages/Ofertas/Ofertas";
// Components
import PublicRoute from "./components/Routes/PublicRoute";
// Providers
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import Cuenta from "./pages/Auth/Cuenta";
import AuthRoute from "./components/Routes/AuthRoute";
import Ordenes from "./pages/Auth/Ordenes/Ordenes";
import Feedback from "./pages/Orden/Feedback";
import Login from "./pages/Auth/Login/Login";
import Comentarios from "./pages/Auth/Comentarios";
import { ScrollToTop } from "./routes";
import Likes from "./pages/Auth/Likes";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthContext>
      <RootContext>
        <BrowserRouter>
          <ScrollToTop />
          <NavbarComp />
          <Switch>
            <PublicRoute
              exact
              path="/"
              component={Landing}
              title="Página Principal"
            />
            <PublicRoute
              path="/inicio"
              component={Landing}
              title="Página Principal"
            />
            <PublicRoute path="/tienda" component={Tienda} title="Tienda" />
            <PublicRoute path="/ofertas" component={Ofertas} title="Ofertas" />
            <PublicRoute
              path="/producto/:id/:text_id"
              component={Producto}
              title="Ver producto"
              registerView={false}
              exact={true}
            />
            <PublicRoute path="/carrito" component={Carrito} title="Carrito" />
            <PublicRoute
              path="/login"
              component={Login}
              title="Iniciar sesión"
            />
            <AuthRoute
              exact
              path="/cuenta"
              component={Cuenta}
              title="Mi cuenta"
            />
            <AuthRoute
              exact={true}
              path="/ordenes"
              component={Ordenes}
              title="Mis ordenes"
            />
            <AuthRoute
              path="/ordenes/:estatus"
              component={Ordenes}
              title="Mis ordenes"
            />
            <AuthRoute
              path="/orden/:id/feedback"
              component={Feedback}
              title="Mis ordenes"
            />
            <AuthRoute
              path="/cuenta/comentarios"
              component={Comentarios}
              title="Mis comentarios"
            />
            <AuthRoute
              path="/cuenta/likes"
              component={Likes}
              title="Mis me gustas"
            />
            <Redirect path="*" to="/" />
          </Switch>
        </BrowserRouter>
      </RootContext>
    </AuthContext>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
