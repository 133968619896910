import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { producto_imagen_default } from "../../constants/images";
import { currency } from "../../libs/currency";

const Producto = ({ ...props }) => {
  const history = useHistory();
  const { item, agregar } = props;

  const getDescuento = () => {
    const { precio_descuento, precio1 } = item;

    const descuento = Math.round(100 - (precio_descuento * 100) / precio1);

    return descuento;
  };

  const verProducto = () => {
    history.push(`/producto/${item.id}/${item.text_id}`);
  };

  return (
    <Card className="card-product">
      <span className="like-product"></span>
      <div>
        <Card.Img
          variant="top"
          style={{ cursor: "pointer" }}
          onClick={() => verProducto()}
          src={item.url || producto_imagen_default}
        />
        <Card.Body
          className="text-center"
          style={{ minHeight: 140, maxHeight: 140 }}
        >
          <span>{item.descripcion}</span>
          <Card.Subtitle className="mb-2 text-muted mt-2">
            {item.tiene_descuento ? (
              <>
                <span>
                  <s className="text-muted">{currency(item.precio)}</s>
                  <br />
                </span>

                <span className="text-success">
                  {currency(item.precio_descuento)}
                  <span className="ml-2 badge badge-success">
                    <strong className="font-weight-bolder">
                      {getDescuento()} %
                    </strong>
                  </span>
                </span>
              </>
            ) : (
              currency(item.precio)
            )}
          </Card.Subtitle>
        </Card.Body>
      </div>
      <button
        className="btn btn-block btn-custom bg-honey text-center text-white"
        onClick={() => agregar(item)}
      >
        AÑADIR
      </button>
    </Card>
  );
};

export default Producto;
