import { useState,useEffect } from 'react';
import conektaHelper from '../services/conekta';

const ConektaHook = () => {
    const [conektaLoaded, setLoaded] = useState(false)
    useEffect(()=>{
        const script = document.createElement('script')
        script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
        script.async = true
        document.body.appendChild(script)
        setLoaded(true)
    },[])

    useEffect(()=>{
        if(conektaLoaded){
            setTimeout(()=>{
                conektaHelper.initConekta(process.env.REACT_APP_CONEKTA_KEY);
            },1500)
        }
    },[conektaLoaded])
    const tokenizar = async (card) =>{
       let response = await conektaHelper.tokenize(card);
       return new Promise((resolve,reject)=>{
           response.onreadystatechange = () =>{
            if(response.readyState === 4 && response.status === 200){
                var respuesta = JSON.parse(response.responseText);
                if (respuesta.id) {
                    resolve({
                        token : respuesta.id,
                    })
                }else{
                    reject ({
                        message : respuesta.message_to_purchaser
                    })
                }
            }
           }
       })
    }
    return{
        tokenizar
    }
};

export default ConektaHook;