import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";

const InfoCalificarComentar = ({ onCalificar = () => {} }) => {
  const [showBenefitInfo, setShowBenefitInfo] = useState(false);

  return (
    <div className="d-flex justify-content-center">
      <Card className="p-5" body style={{ maxWidth: 700 }}>
        <h3
          className="text-secondary text-center mb-4"
          style={{ fontWeight: 600 }}
        >
          Calificación y Comentarios
        </h3>
        <p className="text-justify">
          Agredeceríamos que nos dieras sus comentarios para contribuir a esta
          comunidad sobre el uso de los productos naturales.
        </p>
        <p className="text-justify">
          Nos interesa promover los productos que te aportan valor y crecer el
          conocimiento empírico en el que se basa la herbolaría.
        </p>

        <div className="text-center mt-5">
          <Button
            size="lg"
            className="text-uppercase p-1"
            variant="success"
            style={{ width: 300 }}
            onClick={onCalificar}
          >
            <i className="far fa-star mr-1"></i>
            Valorar
          </Button>
          {/* <Button
            size="lg"
            className="text-uppercase border border-grey text-success mt-2 p-1"
            variant="text"
            style={{ width: 300 }}
          >
            <i className="far fa-comment-alt mr-1"></i>
            Comentar
          </Button> */}
        </div>
        <div className="text-center">
          <Button
            className="mt-3"
            variant="text"
            size="sm"
            onClick={() => setShowBenefitInfo(!showBenefitInfo)}
          >
            <i className="fas fa-info-circle mr-1 text-muted"></i>
            Beneficios de escribir un comentario
          </Button>
          {showBenefitInfo && (
            <section className="d-flex justify-content-center">
              <article
                className="text-muted text-justify p-3"
                style={{ fontSize: 13, maxWidth: 350 }}
              >
                <p>(1) Ganar el reconocimiento de otros usuarios</p>
                <p>
                  (2) Apoyar a los usuarios con los resultados obtenidos sobre
                  los productos
                </p>
                <p>
                  (3) Cuando un comentario es considerado de buena calidad
                  recibiras 50 puntos extra.
                </p>
                <p>
                  *100 puntos = $1. Puedes usar los puntos para pagar hasta el
                  70% del importe total de tu compra (solo se incluye el precio
                  de los productos no del envío).
                </p>
              </article>
            </section>
          )}
        </div>
      </Card>
    </div>
  );
};

export default InfoCalificarComentar;
