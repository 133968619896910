import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  DistanceMatrixService,
  Marker,
} from "@react-google-maps/api";
import { getCurrentPosition } from "../../libs/gps";
import { FormControl } from "react-bootstrap";

const googleMapsLibraries = ["places"];
const googleMapsApiKey = process.env.REACT_APP_API_KEY_MAPS;

const Maps = ({
  width,
  height,
  zoom = 12,
  distanceOrigin = null,
  handleRoutes = () => {},
}) => {
  const [center, setCenter] = useState(null);
  const [ubicacion, setUbicacion] = useState(null);
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [domicilio, setDomicilio] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [getDistance, setGetDistance] = useState({
    call: false,
    withAdress: false,
  });

  const containerStyle = {
    width,
    height,
  };

  useEffect(() => {
    const init = async () => {
      const coords = await getCurrentPosition();

      setCenter(coords);
    };

    init();
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoadAutoComplete = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlacesChanged = () => {
    const { geometry, formatted_address, address_components } =
      autocomplete.getPlace();

    map.setCenter(geometry.location);

    setCiudad(address_components[3].long_name);
    setUbicacion(geometry.location);
    setDomicilio(formatted_address);
    // Call distanceMatrix
    setGetDistance({
      call: true,
      withAdress: false,
    });
  };

  const onDistance = (response) => {
    console.log({ response });
    // Prevent Calling again distanceMatrix
    const { withAdress } = getDistance;

    setGetDistance({ ...getDistance, call: false });

    if (withAdress) {
      const domicilio = response.destinationAddresses[0];

      setCiudad(domicilio.split(",")[2]);

      setDomicilio(domicilio);
    }

    handleRoutes({
      coords: ubicacion,
      address: domicilio,
      distance: response,
      city: ciudad,
    });
  };

  const onChangeAddress = (event) => {
    setDomicilio(event.currentTarget.value);
  };

  const onManualPosition = (event) => {
    setUbicacion(event.latLng);
    setGetDistance({
      call: true,
      withAdress: true,
    });
  };

  const CargandoMapa = () => {
    return (
      <>
        <i className="fas fa-spinner fa-pulse"></i> Cargando Mapa
      </>
    );
  };

  if (!center) {
    return (
      <p>
        <i className="fas fa-spinner fa-pulse"></i> Cargando Mapa
      </p>
    );
  }

  return (
    <LoadScript
      googleMapsApiKey={googleMapsApiKey}
      libraries={googleMapsLibraries}
      loadingElement={<CargandoMapa />}
    >
      {!center && <CargandoMapa />}

      {center && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          clickableIcons={false}
          onClick={onManualPosition}
        >
          {getDistance.call && (
            <DistanceMatrixService
              options={{
                origins: [distanceOrigin],
                destinations: [ubicacion],
                travelMode: "DRIVING",
              }}
              callback={onDistance}
            />
          )}
          <Autocomplete
            onPlaceChanged={onPlacesChanged}
            onLoad={onLoadAutoComplete}
          >
            <FormControl
              style={{
                position: "absolute",
                top: 0,
                height: 50,
                width,
                textOverflow: "ellipsis",
              }}
              value={domicilio}
              onChange={onChangeAddress}
              placeholder="Ingrese el domicilio"
            />
          </Autocomplete>

          {ubicacion && (
            <Marker
              onDragEnd={onManualPosition}
              draggable
              position={ubicacion}
            />
          )}
          <></>
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default Maps;
