import React, { useContext } from "react";
// Hooks
import { getProductosOfertas } from "../../hooks/ProductoHook";
// Components
import Producto from "../../components/productos/ProductoList";
import { useQuery } from "react-query";
import { Store } from "../../context/RootContext";
import { Col, Row } from "react-bootstrap";

function Ofertas() {
  const { agregarProducto } = useContext(Store);

  const { data: ofertas, isLoading } = useQuery(
    "ofertas",
    getProductosOfertas,
    {
      staleTime: Infinity,
    }
  );

  const agregar = (producto) => {
    agregarProducto(
      {
        ...producto,
        cantidad: 1,
      },
      1
    );
  };

  return (
    <div className="container">
      <div>
        <h1 className="my-5 text-center font-weight-light">
          ¡Todas nuestras ofertas!
        </h1>
      </div>

      {isLoading ? (
        <h3 className="text-center mt-3">
          <i className="fas fa-spinner fa-pulse"></i> Cargando ofertas
        </h3>
      ) : ofertas.length > 0 ? (
        <Row>
          {ofertas.map((oferta, idx) => (
            <Col lg={4} md={6} key={idx} className="mb-5">
              <Producto item={oferta} agregar={agregar} />
            </Col>
          ))}
        </Row>
      ) : (
        <>
          <h6 className="text-center font-weight-bold">
            <i className="fas fa-info-circle text-info"></i> ¡No hay ofertas que
            disponibles!
          </h6>
        </>
      )}
    </div>
  );
}

export default Ofertas;
