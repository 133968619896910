import React, { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// Context
import { Store } from "../../context/RootContext";
// Styles
import "./Carrito.sass";
// Libs
import { currency } from "../../libs/currency";
import Pago from "../../hooks/Pago";

const PanelCarrito = ({
  carrito,
  agregar,
  restar,
  datos_envio,
  solo_info = false,
  titulo = "Carrito",
  tienda = null,
  recoger = false,
  cupon,
  loadingCupon,
}) => {
  const { eliminarProducto } = useContext(Store);
  const [editCarrito, setEditCarrito] = useState(false);

  if (!carrito) {
    return null;
  }

  return (
    <div className="carrito">
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span
          className="text-muted carrito__title-section"
          style={{ marginBottom: 0 }}
        >
          {titulo}
        </span>
        <div>
          {carrito.length > 0 && (
            <i
              role="button"
              className="fas fa-edit"
              onClick={() => setEditCarrito(!editCarrito)}
              style={{ marginRight: 15 }}
            ></i>
          )}
          <span className="badge badge-secondary badge-pill">
            {carrito.length}
          </span>
        </div>
      </h4>
      <ul className="list-group mb-3">
        {carrito.map((item) => (
          <li
            key={item.id}
            className="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 className="my-0">
                {item.descripcion}{" "}
                {item.cantidad > 1 ? "(x" + item.cantidad + ")" : null}
              </h6>
              <small className="text-muted">Cantidad</small>
              <p>
                <small
                  role="button"
                  className="text-muted"
                  onClick={() => restar(item.id)}
                >
                  <i className="fas fa-minus"></i>
                </small>
                {item.cantidad}
                <small
                  role="button"
                  className="text-muted"
                  onClick={() => agregar(item.id)}
                >
                  <i className="fas fa-plus"></i>
                </small>
              </p>
            </div>
            {editCarrito ? (
              <div>
                <button
                  className="btn btn-block btn-custom text-center text-white"
                  style={{ backgroundColor: "red" }}
                  onClick={() => eliminarProducto(item.id)}
                >
                  Eliminar
                </button>
              </div>
            ) : (
              <>
                {!solo_info && (
                  <span className="text-muted">{currency(item.precio)}</span>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
      {!solo_info && (
        <div className="mb-3 container">
          <TotalCarrito
            loadingCupon={loadingCupon}
            datos_envio={datos_envio}
            recoger={recoger}
            tienda={tienda}
            cupon={cupon}
          />
        </div>
      )}
    </div>
  );
};

const TotalCarrito = ({
  datos_envio,
  recoger,
  tienda,
  cupon,
  loadingCupon,
}) => {
  const { costo_envio } = datos_envio;

  const { getPagoInfo } = Pago();

  const { subtotal, descuento, total, precio_original, envio_gratis } =
    getPagoInfo(costo_envio);

  const isCuponApplied = cupon.data !== null;
  const costo_envio_formato =
    datos_envio.costo_envio !== null
      ? currency(datos_envio.costo_envio.toFixed(2))
      : "";

  if (loadingCupon) {
    return (
      <Card body>
        Procesando <i className="fas fa-spinner fa-pulse ml-3"></i>
      </Card>
    );
  }

  return (
    <>
      <Row className="row">
        <Col md={6}>
          <label>Subtotal: </label>
          <p>{currency(subtotal.toFixed(2))}</p>
        </Col>
        <Col md={6} className="mt-2 mt-md-0">
          <label>Costo envío: </label>
          {datos_envio.costo_envio === null ? (
            <p className="font-weight-bolder">Por definir</p>
          ) : (
            <>
              {envio_gratis ? (
                <p className="font-weight-bold">
                  <span className="text-success mr-2">$0</span>
                  <s>{costo_envio_formato}</s> <small>(Costo original)</small>
                </p>
              ) : (
                <p>{costo_envio_formato}</p>
              )}
            </>
          )}
        </Col>
        {isCuponApplied && (
          <Col className="my-2" md={12}>
            <strong>Descuento: </strong>
            <span className="text-success font-weight-bolder">
              {currency(descuento)} - {cupon.data.nombre}{" "}
              <i className="fas fa-tags mr-1"></i>
            </span>
          </Col>
        )}
      </Row>
      {(datos_envio.costo_envio !== null && recoger === false) ||
      (recoger === true && tienda !== null) ? (
        <Card className="mt-3">
          <Card.Body>
            <Card.Text>
              <strong>
                Total a pagar{" "}
                <span className="text-success">${total.toFixed(2)}</span>
                {isCuponApplied && (
                  <>
                    <s className="text-black ml-2">
                      {currency(precio_original)}
                    </s>{" "}
                    <small>(Precio original)</small>
                  </>
                )}
              </strong>
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <div className="alert alert-info mt-3" role="alert">
          <p>
            <i className="fas fa-info-circle"></i> Ingrese la dirección de
            entrega o indique en que tienda va a recoger su compra
          </p>
        </div>
      )}
    </>
  );
};

export default PanelCarrito;
