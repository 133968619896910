const conektaHelper = {
    initConekta: (publicKey) => {
      window.Conekta.setLanguage("es");
      window.Conekta.setPublicKey(publicKey);
    },
    getCardBrand: (cardNumber) => {
      return window.Conekta.card.getBrand(cardNumber)
    },
    validateCardNumber: (cardNumber) => {
      return window.Conekta.card.validateNumber(cardNumber)
    },
    validateCvc: (cvc) => {
      return window.Conekta.card.validateCVC(cvc)
    },
    validateExpirationDate: (expiryMonth, expiryYear) => {
      return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
    },
    tokenize: (tokenParams) => {
        return new Promise((resolve,reject)=>{
            let successCallback = function(token) {
                return token.id;
            };
    
            let errorCallback = function(response) {
                return response.message_to_purchaser;
            };
    
            let token = window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
            // var respuesta = JSON.parse(token.responseText);
            window.token = token
            resolve(token);
        });
        
    }
  }
  
  export default conektaHelper