import React, { useContext, useEffect, useState } from "react";
// Context
import { Store } from "../../context/RootContext";
// Libs components
import { Form, Row, Col, Card } from "react-bootstrap";
// Hooks
import Pago from "../../hooks/Pago";
// Components
import Tarjeta from "../../components/Pagos/Tarjeta";
import Oxxo from "../../components/Pagos/Oxxo";
import Maps from "../../components/Maps/maps";
import LoadingButton from "../../components/LoadingButton";
import PanelCarrito from "./PanelCarrito";
// Styles
import "./Carrito.sass";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { alert } from "../../constants/alert";
import { Auth } from "../../context/AuthContext";
import { MobileLayout } from "../../components/Grid/Grid";
import ModalAlertCuenta from "./components/ModalAlertaCuenta";
import { tiendas } from "./utils";
import Cupon from "./components/Cupon";

const Carrito = () => {
  const {
    carrito,
    agregar,
    restar,
    cupon,
    clearCupon,
    verificarCupon,
    loadingCupon,
  } = useContext(Store);
  const { authenticated, userData } = useContext(Auth);
  const [showAlertCuenta, setShowAlertCuenta] = useState(false);

  const defaultValues = authenticated
    ? {
        ...userData,
        correo: userData.email,
        telefono: userData.telefono,
      }
    : {};

  useEffect(() => {
    if (!authenticated) {
      setShowAlertCuenta(true);
    }
  }, [authenticated]);

  const { setValoresPago, pagar, pago, loading } = Pago();
  const [recoger, setRecoger] = useState(false);
  const [tipoPago, setTipoPago] = useState("Tarjeta");
  const { register, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const [datos_envio, setDatosEnvio] = useState({
    costo_envio: null,
    direccion: "",
    coordenadas: {},
    tienda: "",
    ciudad: "",
  });

  const [datosMapa, setDatosMapa] = useState({
    costo_envio: null,
    direccion: "",
    coordenadas: {},
    ciudad: "",
  });

  const handleClickRecoger = (e) => {
    const recoger = e.target.checked;

    if (!recoger) {
      setDatosEnvio({
        ...datos_envio,
        ...datosMapa,
      });
    } else {
      setDatosEnvio({
        costo_envio: 0,
        direccion: "",
        coordenadas: {},
      });
    }

    setRecoger(recoger);
  };

  const handlePosition = (response) => {
    const route = response.distance.rows[0].elements[0];

    const distance = parseFloat(route.distance.value);

    const calculo = Math.round(distance / 100);
    let cost = 0;

    if (calculo < 30) {
      cost = 30;
    } else if (calculo > 100 && calculo < 180) {
      cost = 100;
    } else if (calculo > 180) {
      cost = 250;
    } else {
      cost = calculo;
    }

    setDatosMapa({
      costo_envio: cost,
      coordenadas: {
        lat: response.coords.lat(),
        lng: response.coords.lng(),
      },
      direccion: response.address,
      ciudad: response.city,
    });

    setDatosEnvio({
      costo_envio: cost,
      direccion: response.address,
      coordenadas: {
        lat: response.coords.lat(),
        lng: response.coords.lng(),
      },
      tienda: null,
      recoger: false,
      ciudad: response.city,
    });
  };

  const handlePagar = (data) => {
    if (data.recoger && (datos_envio.tienda === "" || !datos_envio.tienda)) {
      Swal.fire(
        "Tienda no seleccionada",
        "No has seleccionado la tienda en la que vas a recoger la orden, por favor selecciona uno.",
        "info"
      );

      return;
    }

    const pago = {
      ...data,
      ...datos_envio,
      tipo_pago: tipoPago,
      carrito,
    };

    pagar(pago);
  };

  const onSelectTienda = (tienda) => {
    setDatosEnvio({
      ...datos_envio,
      tienda: tienda.id,
      ciudad: tienda.ciudad,
    });
  };

  useEffect(() => {
    if (carrito.length === 0) {
      Swal.fire({
        title: "Carrito vacio",
        text: "Agregue productos a su carrito para proceder con la compra",
        icon: "info",
        confirmButtonText: "Entendido",
      });
    }
  }, [carrito]);

  return (
    <div className="mt-5 skin-light mb-5 carrito">
      <div className="row">
        <div className="col-md-4 order-md-2 mb-4">
          <PanelCarrito
            loadingCupon={loadingCupon}
            cupon={cupon}
            agregar={agregar}
            restar={restar}
            carrito={carrito}
            pago={pago}
            tienda={datos_envio.tienda}
            recoger={recoger}
            datos_envio={datos_envio}
          />
        </div>

        <div className="col-md-8 order-md-1">
          <Form
            onSubmit={handleSubmit(handlePagar, () => {
              alert("Llene todos los campos", "warning");
            })}
          >
            <Card>
              <Card.Body>
                <h3 className="carrito__title-section">Datos de contacto</h3>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        {...register("nombre", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Apellido</Form.Label>
                      <Form.Control
                        {...register("apellido", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Correo electrónico (Opcional)</Form.Label>
                      <Form.Control
                        type="email"
                        {...register("correo", {
                          required: true,
                          onChange: (e) => {
                            setValue("correo", e.currentTarget.value);
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        {...register("telefono", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <hr className="mb-4" />
                <h3 className="carrito__title-section">Datos de entrega</h3>

                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    {...register("recoger")}
                    onClick={handleClickRecoger}
                    id="recoger"
                  />
                  <label className="custom-control-label" htmlFor="recoger">
                    Recoger en tienda
                  </label>
                </div>

                <hr className="mb-4" />
                <div className={`row ${!recoger && "d-none"}`}>
                  <div className="col">
                    <h6 className="font-weight-bold mb-3">
                      {" "}
                      <i className="fas fa-info-circle text-muted"></i>{" "}
                      Selecciona la tienda donde deseas recoger el producto
                    </h6>
                    <div className="list-group">
                      {tiendas.map((tienda, idx) => {
                        const { id, sucursal, domicilio, coords, colonia } =
                          tienda;
                        return (
                          <div
                            key={idx}
                            className={`list-group-item list-group-item-action ${
                              datos_envio.tienda === id ? "active" : ""
                            }`}
                            onClick={() => onSelectTienda(tienda)}
                          >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">{sucursal}</h5>
                              <small>
                                <a
                                  className={`${
                                    datos_envio.tienda === id
                                      ? "text-white"
                                      : "text-danger"
                                  }`}
                                  href={`https://maps.google.com/?q=${coords.lat},${coords.lng}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fas fa-location-arrow"></i> Ver
                                  ubicación
                                </a>
                              </small>
                            </div>
                            <p className="mb-1">{domicilio}</p>
                            <small>Colonia: {colonia} </small>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className={`row ${recoger && "d-none"} `}>
                  <div className="col-12">
                    <Maps
                      zoom={16}
                      width="100%"
                      height="400px"
                      distanceOrigin={tiendas[1].coords}
                      handleRoutes={handlePosition}
                    />
                  </div>
                </div>

                <div className="d-block d-md-none">
                  <hr className="mb-4" />
                  <PanelCarrito
                    loadingCupon={loadingCupon}
                    cupon={cupon}
                    pago={pago}
                    datos_envio={datos_envio}
                  ></PanelCarrito>
                </div>

                <hr className="mb-4" />

                <h4 className="mb-3 carrito__title-section">Forma de pago</h4>

                <Cupon
                  verificarCupon={verificarCupon}
                  clearCupon={clearCupon}
                  cupon={cupon}
                />

                <div className="d-block my-3">
                  <fieldset>
                    <Form.Group as={Row}>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          type="radio"
                          label="Tarjeta"
                          name="tipo_pago"
                          id="tipo_pago_tarjeta"
                          defaultChecked={true}
                          onClick={() => {
                            setTipoPago("Tarjeta");
                          }}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Oxxo"
                          name="tipo_pago"
                          id="tipo_pago_oxxo"
                          onClick={() => {
                            setTipoPago("Oxxo");
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </fieldset>
                </div>
                <div className="row">
                  <div className="col">
                    {tipoPago === "Tarjeta" ? (
                      <Tarjeta
                        register={register}
                        setValoresPago={setValoresPago}
                        pago={pago}
                      />
                    ) : (
                      <Oxxo setValue={setValue} register={register} />
                    )}
                  </div>
                </div>
                <MobileLayout>
                  <hr />
                  <PanelCarrito
                    loadingCupon={loadingCupon}
                    cupon={cupon}
                    agregar={agregar}
                    restar={restar}
                    carrito={carrito}
                    pago={pago}
                    tienda={datos_envio.tienda}
                    recoger={recoger}
                    datos_envio={datos_envio}
                  />
                </MobileLayout>

                <hr className="mb-4" />
                <span className="text-muted mb-4">
                  <i className="fas fa-lock"></i> Tu compra es segura, al
                  continuar aceptas los terminos y condiciones de{" "}
                  <strong>Bee's</strong>
                </span>
                <LoadingButton
                  loading={loading}
                  disabled={carrito.length === 0}
                  nativeType="submit"
                  customClass="btn-custom btn-block bg-honey text-center text-white mt-3"
                >
                  Pagar
                </LoadingButton>
                <small className="text-muted mt-3 font-weight-bold">
                  ¿Tienes dudas o problemas al realizar la compra? comunicate
                  con nosotros llama al número (662) 218 21 16
                </small>
              </Card.Body>
            </Card>
          </Form>
        </div>
      </div>
      <ModalAlertCuenta
        isOpen={showAlertCuenta}
        onClose={() => setShowAlertCuenta(false)}
      />
    </div>
  );
};

export default Carrito;
