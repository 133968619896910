import React, { createContext, useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
// Libs
import { createNotification } from "../libs/utils";
import { defaultCupon, useCupon } from "../hooks/useCupon";
import { useCarrito } from "../hooks/useCarrito";
import { debounce } from "lodash";
import { useRef } from "react";

export const Store = createContext({
  carrito: [],
  agregarProducto: () => {},
  cantidadCarrito: 0,
  eliminarProducto: () => {},
  agregar: () => {},
  restar: () => {},
  createNotification: () => {},
  clearCarrito: () => {},
  cupon: { ...defaultCupon },
  clearCupon: () => {},
  verificarCupon: () => {},
  loadingCupon: false,
});

const RootContext = ({ ...props }) => {
  // Debounce function when the shop cart changed.
  const onChangeCarrito = useRef(
    debounce((productos, cupon) => {
      if (cupon.data && productos.length > 0) {
        verificarCodigo(cupon.data.codigo, productos, false);
      } else if (cupon.data && productos.length === 0) {
        clearCupon();
      }
    }, 1000)
  ).current;

  const { children } = props;
  const { cupon, clearCupon, verificarCodigo, loading } = useCupon();
  const {
    carrito,
    cantidadCarrito,
    agregarProducto,
    clearCarrito,
    eliminarProducto,
    restar,
    agregar,
  } = useCarrito({
    // Evento cuando se actualiza el carrito.
    onChange: (productos) => {
      onChangeCarrito(productos, cupon);
    },
  });

  const verificarCupon = async (codigo) => {
    await verificarCodigo(codigo, carrito);
  };

  // Cleanup debounce function
  useEffect(() => {
    return () => {
      onChangeCarrito.cancel();
    };
  }, [onChangeCarrito]);

  return (
    <Store.Provider
      value={{
        carrito,
        cantidadCarrito,
        agregarProducto,
        eliminarProducto,
        agregar,
        restar,
        createNotification,
        clearCarrito,
        cupon,
        clearCupon,
        verificarCupon,
        loadingCupon: loading,
      }}
    >
      {children}
      <NotificationContainer />
    </Store.Provider>
  );
};

export default RootContext;
