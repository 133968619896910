import axios from "axios";
import { FirebaseService } from "./firebase";

export const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

HTTP.interceptors.request.use(async (config) => {
  const token = await FirebaseService.getAccessToken();

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
    };
  }
  return config;
});
