import React from "react";

import {
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../../../components/Forms/ErrorMessage";
import LoadingButton from "../../../../components/LoadingButton";

const RegistroForm = ({
  onRegister = () => {},
  formError = null,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  return (
    <Form onSubmit={handleSubmit(onRegister)}>
      <h3 className="mb-5 text-center">REGISTRO</h3>
      <FormGroup>
        <FormLabel>E-mail</FormLabel>
        <FormControl
          {...register("email", {
            required: "El correo electrónico es requerido",
          })}
          type="email"
        ></FormControl>
        <ErrorMessage errors={errors} name="email" />
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup>
            <FormLabel>Nombre</FormLabel>
            <FormControl
              {...register("nombre", {
                required: "El nombre es requerido",
              })}
            ></FormControl>
            <ErrorMessage errors={errors} name="nombre" />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <FormLabel>Apellido</FormLabel>
            <FormControl
              {...register("apellido", {
                required: "El apellido es requerido",
              })}
            ></FormControl>
            <ErrorMessage errors={errors} name="apellido" />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <FormLabel>Teléfono</FormLabel>
        <FormControl
          {...register("telefono", {
            required: "El teléfono es requerido",
            minLength: {
              value: 10,
              message: "Deben ser mínimo 10 caracteres",
            },
          })}
        ></FormControl>
        <ErrorMessage errors={errors} name="telefono" />
      </FormGroup>
      <FormGroup>
        <FormLabel>Contraseña</FormLabel>
        <FormControl
          type="password"
          {...register("password", {
            minLength: {
              value: 8,
              message: "La contraseña debe tener al menos 8 caracteres",
            },
            required: "La contraseña es requerida",
          })}
        ></FormControl>
        <ErrorMessage errors={errors} name="password" />
      </FormGroup>
      <FormGroup>
        <FormLabel>Confirmar contraseña</FormLabel>
        <FormControl
          type="password"
          {...register("confirm_password", {
            required: "La confirmación de contraseña es requerida",
            validate: {
              verifyPass: (value) =>
                value === getValues("password") ||
                "Las contraseñas no coinciden",
            },
          })}
        ></FormControl>
        <ErrorMessage errors={errors} name="confirm_password" />
      </FormGroup>
      <LoadingButton
        nativeType="submit"
        loading={loading}
        type="success"
        block
        size="lg"
      >
        REGÍSTRATE
      </LoadingButton>
      {formError && (
        <Form.Text className="text-danger mt-3">
          <i className="fas fa-exclamation-triangle mr-1"></i> {formError}
        </Form.Text>
      )}
    </Form>
  );
};

export default RegistroForm;
