import React, { useEffect, useState } from "react";
import { Card, Col, Container, Jumbotron, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Rate from "../../../components/Rate";
import { createNotification } from "../../../libs/utils";
import { ClienteService } from "../../../services/ClienteService";

const Comentarios = () => {
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await ClienteService.comentarios();

        setRates(data);
      } catch (err) {
        createNotification(
          "error",
          "Error al momento de obtener los comentarios"
        );
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return (
      <h3 className="text-center mt-4">
        <i className="fas fa-spinner fa-pulse mr-2"></i>
        Cargando comentarios
      </h3>
    );
  }

  return (
    <Container className="mb-5">
      <h3 className="text-center mt-5 mb-4 text-muted">Mis comentarios</h3>
      {rates.map((rate, key) => (
        <Comentario key={key} rate={rate} />
      ))}
      {rates.length === 0 && (
        <div className="d-flex justify-content-center text-center">
          <Jumbotron style={{ width: 600 }}>
            <h4>
              <i className="fas fa-info-circle"></i> No hay datos
            </h4>
            <p>Actualmente no tienes ningún comentario registrado</p>
          </Jumbotron>
        </div>
      )}
    </Container>
  );
};

const Comentario = ({ rate }) => {
  const { producto } = rate;
  const history = useHistory();

  const redirectProducto = () => {
    history.push(`/producto/${producto.id}/${producto.text_id}`);
  };

  return (
    <div className="d-flex justify-content-center">
      <Card
        className="my-3"
        style={{ maxWidth: 600, minWidth: 600, border: "2px solid lightgrey" }}
      >
        <Card.Body>
          <Card.Title
            className="mb-4 text-success"
            style={{ cursor: "pointer" }}
            onClick={redirectProducto}
          >
            {producto.descripcion}
          </Card.Title>

          <Row>
            <Col xs={5}>
              <img
                style={{ cursor: "pointer" }}
                onClick={redirectProducto}
                className="d-block"
                width={200}
                src={producto.url}
                alt={`producto ${producto.descripcion}`}
              />
            </Col>
            <Col>
              <label className="text-muted font-weight-bolder">
                Calificacion
              </label>
              <div>
                <Rate disabled showText defaultValue={rate.rate} />
              </div>
              <label className="text-muted font-weight-bolder mt-4">
                Comentario:
              </label>
              <p>{rate.comentario}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Comentarios;
