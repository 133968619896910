import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const Tarjeta = ({ register }) => {
  const mapExpiracion = (event) => {
    const { value } = event.currentTarget;

    if (value.length === 2) {
      if (value.indexOf("/") === -1) {
        return value + "/";
      }
    }

    return value;
  };

  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Nombre que aparece en la tarjeta</Form.Label>
            <Form.Control
              {...register("cc_name", {
                required: true,
              })}
            />
            <Form.Text className="text-muted">
              Tal cual viene en la parte frontal de su tarjeta
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Número de la tarjeta</Form.Label>
            <Form.Control
              {...register("cc_number", {
                required: true,
              })}
              maxLength={16}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={3} className="mb-3">
          <Form.Group>
            <Form.Label>Expiración</Form.Label>
            <Form.Control
              {...register("cc_expiration", {
                required: true,
              })}
              maxLength={5}
              onChange={(event) => {
                event.target.value = mapExpiracion(event);
              }}
              pattern="([0-9]{2}[/]?){2}"
            />
          </Form.Group>
        </Col>
        <Col md={3} className="mb-3">
          <Form.Group>
            <Form.Label>CVV</Form.Label>
            <Form.Control
              {...register("cc_cvv", {
                required: true,
              })}
              maxLength={3}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Tarjeta;
