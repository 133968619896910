import React from 'react';
import './Footer.sass';
// PDF
import terminos_condiciones from '../../assets/docs/terminos_y_condiciones.pdf';


const Footer = () => {
    return (
        <div className="row footer p-3">
            <div className="col-md-6 order-md-1 order-3 mt-5 text-center">
                <h3 className="footer__title text-uppercase">
                    Síguenos en nuestras redes
                </h3>
                <p>
                    <a href="https://www.facebook.com/beestiendanatural" target="_blank" rel="noopener noreferrer" className="btn btn-secondary footer__social-btn">
                        <i className="fab fa-facebook-f fa-2x"></i>
                    </a>
                    <a href="https://www.instagram.com/tiendanaturalbees/" target="_blank" rel="noopener noreferrer" className="btn btn-secondary footer__social-btn ml-2">
                        <i className="fab fa-instagram fa-2x"></i>
                    </a>

                </p>
                <hr className="text-white" />

                <div className="row">
                    <div className="col-md-6">
                        <a href={terminos_condiciones} target="_blank" rel="noopener noreferrer" className="text-white">
                            Terminos y condiciones
                        </a>
                    </div>

                    <div className="col-md-6">
                        <p className="text-white">
                            &copy; { new Date().getFullYear() } Bee's Tienda Natural
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-md-6 order-1 text-center">
                <span className="logo-footer" ></span>
            </div>
        </div>
    );
};

export default Footer;