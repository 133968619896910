/**
 *
 * @param {string} value
 * @param {Intl.DateTimeFormatOptions} options
 * @param {string | string []} locale
 * @returns string
 */
export const dateFormat = (
  value = new Date(),
  options = {
    dateStyle: "full",
  },
  locale = "es-mx"
) => {
  return Intl.DateTimeFormat(locale, options).format(new Date(value));
};
