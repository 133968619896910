import React, { useCallback, useContext, useEffect, useState } from "react";
// Context
import { Store } from "../../context/RootContext";
// Libs
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
// Constants
import { AnalyticsConfig } from "../../constants/analytics.config";
import { ProductoService } from "../../services/ProductoService";
import { createNotification } from "../../libs/utils";
import { Button, Jumbotron, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import Slider from "../../components/Slider";
import { getProductosOfertas } from "../../hooks/ProductoHook";
import ProductoInfo from "./components/ProductoInfo";
import { Auth } from "../../context/AuthContext";
import Comentario from "../../components/Comentario";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

const Producto = ({ ...props }) => {
  const { agregarProducto, carrito, agregar, restar } = useContext(Store);
  const { match } = props;
  const {
    params: { text_id: productoTextId, id },
  } = match;
  const productoId = parseInt(id);

  const descripcionProducto = productoTextId.split("-").join(" ").toUpperCase();

  const { userData, authenticated } = useContext(Auth);

  const { data: ofertas, isLoading: loadingOfertas } = useQuery(
    "ofertas",
    getProductosOfertas,
    {
      staleTime: Infinity,
    }
  );

  const [loading, setLoading] = useState(true);
  const [producto, setProducto] = useState(null);
  const location = useLocation();
  const [notFound, setNotFound] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [errorProducto, setErrorProducto] = useState(false);
  const [showCompartir, setShowCompartir] = useState(false);

  useEffect(() => {
    ReactGA.initialize(AnalyticsConfig.trackingId);
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  const getProducto = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await ProductoService.get(productoId, productoTextId);
      setProducto({
        ...data,
        cantidad: 1,
      });

      setComentarios(data.comentarios);
      setNotFound(false);
      setErrorProducto(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNotFound(true);
        createNotification("warning", "El producto no se ha encontrado");
      } else {
        setErrorProducto(true);
        createNotification("error", "Error al consultar producto");
      }
    } finally {
      setLoading(false);
    }
  }, [productoId, productoTextId]);

  useEffect(() => {
    getProducto();
  }, [getProducto]);

  if (loading) {
    return (
      <h3 className="text-center mt-5">
        <i className="fas fa-spinner fa-pulse"></i> Cargando producto
      </h3>
    );
  }

  return (
    <>
      <Helmet>
        <title>{descripcionProducto} - Bee's Tienda Natural</title>
      </Helmet>
      <div className="container mt-5">
        {notFound && (
          <>
            <h5 className="text-center text-muted text-uppercase font-weight-bold">
              ¡Producto no encontrado!
            </h5>

            <img
              className="w-100"
              src={require("../../assets/images/producto_not_found.png")}
              alt="Imagen producto no encontrado"
            />
          </>
        )}

        {!notFound && producto && (
          <ProductoInfo
            onCompartir={() => setShowCompartir(true)}
            producto={producto}
            productoId={productoId}
            productoTextId={productoTextId}
            carrito={carrito}
            agregar={agregar}
            restar={restar}
            agregarProducto={agregarProducto}
          />
        )}

        {errorProducto && (
          <Jumbotron>
            <div className="text-center">
              <h3>
                <i className="fas fa-exclamation-triangle"></i> No se pudo
                mostrar el producto
              </h3>
              <p>Ocurrio un error al momento de obtener el producto.</p>

              <Button onClick={getProducto} size="lg" className="btn-honey">
                <i className="fas fa-sync mr-2"></i>
                Reintentar
              </Button>
            </div>
          </Jumbotron>
        )}

        {comentarios.length > 0 && (
          <div className="my-5">
            <h3 className="text-success mb-4 mb-md-3">
              Evaluaciones de los clientes
            </h3>
            <h6 style={{ fontWeight: 600 }}>Todos los comentarios</h6>
            <hr />
            {comentarios.map((comentario) => (
              <Comentario
                authenticated={authenticated}
                userData={userData}
                key={comentario.id}
                comentario={comentario}
              />
            ))}
          </div>
        )}
      </div>
      {!loadingOfertas && comentarios.length === 0 && ofertas.length > 0 && (
        <Ofertas ofertas={ofertas} agregar={agregar} />
      )}
      <ShareModal
        show={showCompartir}
        onClose={() => setShowCompartir(false)}
        producto={producto}
      />
    </>
  );
};

const Ofertas = ({ ofertas, agregar }) => {
  return (
    <div className="my-5">
      <h3
        style={{ fontSize: 36, color: "darkgrey" }}
        className="text-uppercase text-center mb-5 font-weight-bold"
      >
        Ofertas del mes
      </h3>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 1200 }}>
          <Slider routeSeeMore="/ofertas" items={ofertas} agregar={agregar} />
        </div>
      </div>
    </div>
  );
};

const ShareModal = ({ show, producto, onClose }) => {
  const url = window.location.href;
  const quote = `Encuentra ${producto.descripcion} en Tienda Bee's Natural`;

  const clipboardProducto = () => {
    navigator.clipboard.writeText(url);
    createNotification("info", "Enlace copiado en el portapapeles");
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Compartir {producto.descripcion}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Comparte este producto en tus redes favoritas</p>
        <div>
          <FacebookShareButton
            hashtag="#beestiendanatural"
            className="mr-1"
            url={url}
            quote={quote}
          >
            <FacebookIcon size={45} round />
          </FacebookShareButton>
          <TelegramShareButton className="mr-1" url={url} title={quote}>
            <TelegramIcon size={45} round />
          </TelegramShareButton>
          <WhatsappShareButton className="mr-1" url={url} title={quote}>
            <WhatsappIcon size={45} round />
          </WhatsappShareButton>
          <TwitterShareButton
            title={quote}
            hashtags={[
              "productosnaturales",
              "tiendanaturista",
              "beestiendanatural",
            ]}
            className="mr-1"
            url={url}
          >
            <TwitterIcon size={45} round />
          </TwitterShareButton>
          <Button
            title="Copiar enlace"
            onClick={clipboardProducto}
            variant="secondary"
            style={{ width: 45, height: 45, borderRadius: "50%" }}
          >
            <i className="fas fa-paste"></i>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Producto;
