import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { producto_imagen_default } from "../../../constants/images";
import { currency } from "../../../libs/currency";
import { dateFormat } from "../../../libs/dateFormat";
import { createNotification } from "../../../libs/utils";
import { OrdenService } from "../../../services/OrdenService";

const Ordenes = () => {
  const { estatus } = useParams();

  useEffect(() => {
    if (estatus && !["procesando", "enviados"].includes(estatus)) {
      window.location.href = window.origin + "/ordenes";
    }
  }, [estatus]);

  const [filtroEstatus, setFiltroEstatus] = useState(estatus || "todos");

  const getOrdenes = async () => {
    try {
      const params = {};

      if (filtroEstatus !== "todos") {
        params.filtro = filtroEstatus;
      }

      const { data } = await OrdenService.getAll(params);
      return data;
    } catch (err) {
      createNotification("error", "Error al obtener las ordenes");
    }
    return [];
  };

  const { isLoading, data: ordenes } = useQuery(
    ["ordenes", filtroEstatus],
    getOrdenes,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const tituloFiltro = useMemo(() => {
    if (filtroEstatus === "todos") {
      return "MIS ORDENES";
    } else if (filtroEstatus === "procesando") {
      return "ENVIOS EN PROCESO";
    } else {
      return "PEDIDOS ENVIADOS";
    }
  }, [filtroEstatus]);

  const Cargando = () => (
    <h3 className="mt-5 text-center">
      <i className="fas fa-spinner fa-pulse"></i> Cargando ordenes
    </h3>
  );

  return (
    <Container className="my-5">
      <Row>
        <Col md={3}>
          <Form.Label className="font-weight-bolder">Filtrar por:</Form.Label>
          <Form.Control
            size="lg"
            onChange={(e) => setFiltroEstatus(e.target.value)}
            as="select"
            custom
            value={filtroEstatus}
          >
            <option value="todos">Todos</option>
            <option value="procesando">En proceso</option>
            <option value="enviados">Enviados</option>
          </Form.Control>
        </Col>
      </Row>
      {isLoading ? (
        <Cargando />
      ) : (
        <div>
          <h2 className="text-center text-success text-uppercase mt-5 mt-md-0">
            {tituloFiltro}
          </h2>
          {ordenes.length > 0 ? (
            ordenes.map((orden) => (
              <div key={orden.conekta_order_id}>
                <hr className="my-5" />
                <Orden data={orden} />
              </div>
            ))
          ) : (
            <Card body className="mt-5 p-3">
              <h5>
                <i className="fas fa-info-circle text-muted"></i> No hay ordenes
                que mostrar
              </h5>
            </Card>
          )}
        </div>
      )}
    </Container>
  );
};

const Orden = ({ data }) => {
  const textoEstatus = useMemo(() => {
    if (!data.pagado) {
      return "PENDIENTE DE PAGO";
    } else {
      return data.estatus.toUpperCase();
    }
  }, [data.pagado, data.estatus]);

  const { monto, costo_envio, descuento, envio_gratis } = data;

  const total_original = parseFloat(monto) + parseFloat(costo_envio);
  let total_descuento = 0;

  if (descuento) {
    total_descuento = total_original - descuento;

    if (envio_gratis) {
      total_descuento -= costo_envio;
    }
  }

  return (
    <>
      <h3 className="text-default text-center text-muted align-self-start">
        Orden #{data.conekta_order_id}
      </h3>
      <Row className="mt-5 mt-md-0">
        <Col md={6}>
          <h4 className="font-weight-bolder text-success">{textoEstatus}</h4>
        </Col>
        <Col md={6} className="text-md-right">
          <p style={{ fontSize: 20 }}>{dateFormat(data.created_at)}</p>
        </Col>
      </Row>

      {data.detalle.map((producto, key) => (
        <OrdenProducto data={producto} key={key} />
      ))}

      <Row>
        <Col md={{ span: 3, offset: 10 }}>
          <p style={{ fontSize: 20 }} className="mb-1 text-muted">
            Envió{" "}
            {envio_gratis ? (
              <>
                <s className="font-weight-bolder mr-1">
                  {currency(costo_envio)}
                </s>{" "}
                <small>(Costo original)</small>
                <br />
                <span className="mr-2 font-weight-bolder text-success">
                  $0.00
                </span>
              </>
            ) : (
              <span>{currency(costo_envio)}</span>
            )}
          </p>
          <br />
          <strong
            className="text-uppercase bg-success text-white p-3 font-weight-bolder"
            style={{ fontSize: 30 }}
          >
            TOTAL {currency(total_descuento || total_original)}
          </strong>
          {total_descuento && (
            <p className="mt-3 font-weight-bold">
              <s className="mr-1">{currency(total_original)}</s>
              <small>(Precio original)</small>
            </p>
          )}
        </Col>
      </Row>
    </>
  );
};

const OrdenProducto = ({ data }) => {
  const history = useHistory();

  const redirectProducto = () => {
    const { id, text_id } = data.producto;
    history.push(`/producto/${id}/${text_id}`);
  };

  return (
    <div className="my-4">
      <Row>
        <Col md={3}>
          <Image
            className="w-100"
            onClick={() => redirectProducto()}
            src={data.producto.url || producto_imagen_default}
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col md={6} className="mt-3 mt-md-0">
          <p
            onClick={() => redirectProducto()}
            className="text-success"
            style={{ fontSize: 23, cursor: "pointer" }}
          >
            {data.producto.descripcion}
          </p>
          <div className="text-muted" style={{ fontSize: 18 }}>
            <p>
              ({data.cantidad}) {currency(data.precio_unitario)}
            </p>
            <p>
              SUBTOTAL{" "}
              {currency(Number(data.cantidad) * Number(data.precio_unitario))}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Ordenes;
