import React from "react";
// Components
import Slider from "../../../components/Slider";

const Promocion = ({
  productos = { ofertas: [], mieles_sonora: [], destacados: [] },
  agregar = () => {},
}) => {
  return (
    <div className="mb-4 ofertasSlider">
      {productos.ofertas.length > 0 && (
        <>
          <h2 className="my-5 text-center text-secondary text-uppercase font-weight-bolder">
            Ofertas del mes
          </h2>

          <div className="d-flex justify-content-center">
            <Slider
              textSeeMore="Ver más ofertas"
              routeSeeMore="/ofertas"
              items={productos.ofertas}
              agregar={agregar}
            />
          </div>
        </>
      )}

      {productos.mieles_sonora.length > 0 && (
        <>
          <h2 className="my-5 text-center text-secondary text-uppercase font-weight-bolder">
            Productos de mieles de sonora
          </h2>
          <div className="d-flex justify-content-center">
            <Slider
              textSeeMore="Ver más productos"
              routeSeeMore={"/tienda?q=mieles de sonora"}
              items={productos.mieles_sonora}
              agregar={agregar}
            />
          </div>
        </>
      )}

      {productos.destacados.length > 0 && (
        <>
          <h2 className="my-5 text-center text-secondary text-uppercase font-weight-bolder">
            Destacados
          </h2>

          <div className="d-flex justify-content-center">
            <Slider items={productos.destacados} agregar={agregar} />
          </div>
        </>
      )}
    </div>
  );
};

export default Promocion;
