import React from "react";
import { Col, Row } from "react-bootstrap";
import Producto from "../components/productos/ProductoList";
const ListaProductos = ({ lista = [], agregar = () => {} }) => {
  return (
    <Row className="row">
      {lista.map((item) => (
        <Col md={3} key={item.clave} className="mb-2">
          <Producto item={item} agregar={agregar} />
        </Col>
      ))}
    </Row>
  );
};

export default ListaProductos;
