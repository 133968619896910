import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Components
import Producto from "../../components/productos/ProductoList";
// Sass
import "./Slider.sass";
import "swiper/css";
import "swiper/css/navigation";
import { DesktopLayout, MobileLayout, TabletLayout } from "../Grid/Grid";
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay]);

const Slider = ({
  items = [],
  agregar = () => {},
  textSeeMore = "Ver más",
  routeSeeMore = "/",
}) => (
  <>
    <DesktopLayout style={{ maxWidth: 1200 }}>
      <SliderItem
        textSeeMore={textSeeMore}
        routeSeeMore={routeSeeMore}
        items={items}
        agregar={agregar}
      />
    </DesktopLayout>

    <TabletLayout style={{ maxWidth: 600 }}>
      <SliderItem
        textSeeMore={textSeeMore}
        routeSeeMore={routeSeeMore}
        slidesPerView={2}
        items={items}
        agregar={agregar}
      />
    </TabletLayout>

    <MobileLayout style={{ maxWidth: 300 }}>
      <SliderItem
        textSeeMore={textSeeMore}
        routeSeeMore={routeSeeMore}
        slidesPerView={1}
        items={items}
        agregar={agregar}
      />
    </MobileLayout>
  </>
);

const SliderItem = ({
  spaceBetween = 50,
  slidesPerView = 3,
  items = [],
  agregar = () => {},
  textSeeMore,
  routeSeeMore,
}) => {
  return (
    <div className="w-100">
      <Swiper
        autoplay
        modules={[Navigation]}
        loop
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation
        wrapperTag="ul"
        tag="section"
      >
        {items.map((producto, idx) => (
          <SwiperSlide tag="li" key={idx} style={{ listStyle: "none" }}>
            <Producto item={producto} agregar={agregar} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="text-right">
        <Link to={routeSeeMore} className="btn btn-light p-3 font-weight-bold">
          {textSeeMore}
        </Link>
      </div>
    </div>
  );
};

export default Slider;
