import React, { createContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { logo_header } from "../constants/images";
import { createNotification } from "../libs/utils";
import { ClienteService } from "../services/ClienteService";
import { FirebaseService } from "../services/firebase";

export const Auth = createContext({
  userData: null,
  authenticated: false,
});

export const AuthContext = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isAuth = Boolean(localStorage.getItem("isAuth"));

    if (isAuth) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    const registerWithProvider = async (user) => {
      try {
        const { providerData } = user;

        const [account_type] = providerData[0].providerId.split("."); // email, google.com

        const { data: cliente } = await ClienteService.register({
          nombre: user.displayName,
          email: user.email,
          fb_uid: user.uid,
          account_type,
        });

        setUserData(cliente);
        setAuthenticated(true);
      } catch (err) {
        createNotification("error", "Error al registrar la cuenta");
      }
    };

    FirebaseService.onAuthStateChanged(async (user) => {
      if (user) {
        const data = await user.getIdToken(true);
        FirebaseService.setSession(data, user);
        localStorage.setItem("isAuth", true);

        try {
          const { data: userData } = await ClienteService.me();
          setUserData(userData);
          setAuthenticated(true);
        } catch (err) {
          if (
            err.response &&
            err.response.data.code === "account/not-register"
          ) {
            await registerWithProvider(user);
          } else {
            createNotification("error", "Error al consultar la cuenta");
          }
        }
      } else {
        setAuthenticated(false);
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Container className="mt-5 text-center">
        <div>
          <img width={300} src={logo_header} alt="Bee's logo" />
        </div>
        <div className="mt-5">
          <h4>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Iniciando
          </h4>
        </div>
      </Container>
    );
  }

  return (
    <Auth.Provider
      value={{
        userData,
        authenticated,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export default AuthContext;
