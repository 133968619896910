import { useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import { currency } from "../../../libs/currency";

const Cupon = ({ cupon, clearCupon, verificarCupon }) => {
  return (
    <div className="row my-3">
      {cupon && cupon.data ? (
        <div className="col-5">
          <CuponInfo data={cupon.data} onClear={clearCupon} />
        </div>
      ) : (
        <div className="col-3">
          <CuponInput onApply={verificarCupon} />
        </div>
      )}
      <div className="col-3"></div>
    </div>
  );
};

const CuponInput = ({ onApply }) => {
  const [codigo, setCodigo] = useState();

  return (
    <FormGroup>
      <label>Aplicar cupón</label>
      <InputGroup>
        <FormControl
          placeholder="Código"
          onChange={(e) => setCodigo(e.target.value)}
        />
        <Button
          variant="success"
          className="ml-1"
          onClick={() => onApply(codigo)}
        >
          Aplicar
        </Button>
      </InputGroup>
    </FormGroup>
  );
};

const CuponInfo = ({ data, onClear }) => {
  const { nombre, descuento, es_porcentaje } = data;

  return (
    <Card>
      <Card.Body>
        <div>
          <h5>
            {nombre}
            <small className="font-weight-bolder text-success ml-2 ">
              Descuento -{" "}
              {es_porcentaje
                ? `${Math.round(descuento)}%`
                : currency(descuento)}
            </small>
          </h5>
        </div>
        <Button
          onClick={onClear}
          className="p-0"
          style={{ fontSize: 12 }}
          size="sm"
          variant="text"
        >
          <i className="fas fa-times mr-1"></i>
          Quitar cupón
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Cupon;
