import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREABASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

export const FirebaseService = {
  session: {
    token: null,
    exp: null,
    user: null,
  },
  createUserWithEmailAndPassword(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  },
  signInWithEmailAndPassword(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  },
  onAuthStateChanged(callback) {
    return onAuthStateChanged(auth, callback);
  },
  signOut() {
    return signOut(auth);
  },
  setSession(access_token, user = null) {
    this.session = {
      ...this.session,
      token: access_token,
      exp: new Date().getTime() + 240000, // 4 minutos
    };

    if (user) {
      this.session.user = user;
    }
  },
  async getAccessToken() {
    const { exp, user } = this.session;

    if (user && new Date().getTime() >= exp) {
      console.log("refresh token");

      const access_token = await user.getIdToken();

      this.setSession(access_token);
    }

    return this.session.token;
  },
  async resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  },
  async GoogleAuth() {
    return signInWithPopup(auth, googleProvider);
  },
};
