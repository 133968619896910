import React, { useEffect, useState } from "react";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Comentario from "../../../components/Comentario";
import { TabletLayout } from "../../../components/Grid/Grid";
import { createNotification } from "../../../libs/utils";
import { LikeService } from "../../../services/LikeService";

const Likes = () => {
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const { data } = await LikeService.all();

        const likes = data.map((item) => {
          return {
            ...item.likeable,
          };
        });

        setLikes(likes);
      } catch (err) {
        createNotification("error", "Error al consultar los me gustas");
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  const redirectProduct = (producto_id, text_id) => {
    history.push(`/producto/${producto_id}/${text_id}`);
  };

  if (loading) {
    return (
      <h3 className="text-center mt-5">
        <i className="fas fa-spinner fa-spin mr-1"></i> Cargando me gustas
      </h3>
    );
  }

  return (
    <Container>
      <h1
        className="text-center text-success text-uppercase mt-5"
        style={{ fontWeight: 600, fontSize: 28 }}
      >
        Me gusta
      </h1>
      <hr />
      <div>
        {likes.map((like, id) => (
          <Row key={id}>
            <Col md={2}>
              <TabletLayout onlyTablet={false}>
                <img
                  src={like.producto.url}
                  className="w-100"
                  alt={`Imagen ` + like.producto.descripcion}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    redirectProduct(like.producto.id, like.producto.text_id)
                  }
                />
              </TabletLayout>
            </Col>
            <Col>
              <p
                style={{ cursor: "pointer" }}
                className="text-success font-weight-bold mb-4"
                onClick={() =>
                  redirectProduct(like.producto.id, like.producto.text_id)
                }
              >
                {like.producto.descripcion}
              </p>
              <Comentario
                comentario={like}
                readOnly={true}
                count={like.likes_count}
                showRate={false}
              />
            </Col>
          </Row>
        ))}

        {likes.length === 0 && (
          <div className="d-flex justify-content-center mt-5">
            <Jumbotron className="text-center" style={{ width: 600 }}>
              <h4>
                <i className="fas fa-info-circle"></i> No hay datos
              </h4>
              <p>Actualmente no tienes ningún me gusta registrado</p>
            </Jumbotron>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Likes;
