import React from "react";
import Helmet from "react-helmet";
import Footer from "../Footer/Footer";
import { Route as RouterRoute } from "react-router-dom";

const Route = ({ title, Component = <div></div>, ...rest }) => {
  return (
    <RouterRoute
      {...rest}
      render={(props) => (
        <div className="container-fluid">
          <Helmet>
            <title>{`${title} - Bee's Tienda Natural`}</title>
          </Helmet>
          <div className="content-body">
            <Component {...props} />
          </div>
          <Footer />
        </div>
      )}
    />
  );
};

export default Route;
