import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
//type success,warning,danger,info
export const alert = (message,type,text,callback = () => {} )=>{
    MySwal.fire({
        title: `<h1>${message}</h1>`,
        text : text,
        icon: type,
    }).then(() => {
        return callback()
    })
}