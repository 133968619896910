import { HTTP } from "./http";

export const ClienteService = {
  register(data) {
    return HTTP.post("cliente", data);
  },
  me() {
    return HTTP.get("me");
  },
  cuenta() {
    return HTTP.get("cliente/cuenta");
  },
  comentarios() {
    return HTTP.get("cliente/comentarios");
  },
};
