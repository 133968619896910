import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const Oxxo = ({ register, setValue }) => {
  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
            {...register("correo", {
              required: true,
              onChange: (e) => {
                setValue("correo", e.currentTarget.value);
              },
            })}
          />
          <Form.Text className="text-muted">
            Le enviaremos una copia de la referencia a su correo
          </Form.Text>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Oxxo;
