import { useState, useContext } from "react";
import { Store } from "../context/RootContext";
import ConektaHook from "./ConektaHook";
import { alert } from "../constants/alert";
import { OrdenService } from "../services/OrdenService";
import { createNotification } from "../libs/utils";
import { useHistory } from "react-router-dom";
const Pago = () => {
  const { carrito, clearCarrito, cupon } = useContext(Store);
  const { tokenizar } = ConektaHook();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleAfterPayment = () => {
    history.push("/ordenes");
    clearCarrito();
  };

  const pagar = async (pago) => {
    const datos_orden = await validar(pago);
    try {
      setLoading(true);
      if (datos_orden === null) return;

      if (cupon.data) {
        datos_orden["cupon_id"] = cupon.data.id;
      }

      const { data } = await OrdenService.create(datos_orden);
      if (!datos_orden.recoger && datos_orden.costo_envio === null) {
        return alert(
          'Asegurese de haber seleccionado una ubicación antes de continuar. Presione el boton "Seleccionar ubicación". '
        );
      }

      const { referencia } = data.order;

      if (referencia !== null) {
        alert(
          "¡Compra exitosa!",
          "success",
          `Pago por Oxxo. Menciona al cajero Oxxo Pay, su referencia de pago es: ${referencia}. Le recordamos copiarla. Tienes 24 horas para realizar el pago.`,
          handleAfterPayment
        );
      } else {
        alert("¡Compra exitosa!", "success", data.text, handleAfterPayment);
      }
    } catch (e) {
      console.log(e.response);
      createNotification("error", "Pago no procesado");
    } finally {
      setLoading(false);
    }
  };

  const validar = async (pago) => {
    const tipoPagosPermitidos = ["Tarjeta", "Oxxo"];

    if (!tipoPagosPermitidos.includes(pago.tipo_pago)) {
      alert("El tipo de pago no esta disponible", "warning");
    }

    if (pago.nombre === "" || pago.apellido === "" || pago.telefono === "") {
      alert("llene todos los campos", "warning");
      return null;
    } else {
      if (!pago.recoger) {
        if (pago.direccion === "") {
          alert("Proporcione una dirección de entrega", "warning");
          return null;
        }
      }
      if (pago.tipo_pago === "Tarjeta") {
        if (
          pago.cc === "" ||
          pago.cc_number === "" ||
          pago.cc_expiration === "" ||
          pago.cc_cvv === ""
        ) {
          alert("Verifique los datos de la tarjeta", "warning");
          return null;
        } else {
          let card = {
            number: pago.cc_number,
            name: pago.cc_name,
            exp_month: pago.cc_expiration.split("/")[0],
            exp_year: "20" + pago.cc_expiration.split("/")[1],
            cvc: pago.cc_cvv,
          };
          try {
            let { token } = await tokenizar({ card: card });
            let orden = {
              nombre: `${pago.nombre} ${pago.apellido}`,
              correo: pago.correo,
              telefono: pago.telefono,
              ciudad: pago.ciudad,
              coordenadas: pago.coordenadas,
              recoger_tienda: pago.recoger,
              direccion: pago.direccion,
              save_info: pago.save_info,
              metodo_pago: pago.tipo_pago,
              carrito: pago.carrito,
              total_pagar: pago.total_pagar,
              card_token: token,
              tienda_id: pago.tienda || "",
              costo_envio: pago.recoger ? 0 : pago.costo_envio,
            };

            return orden;
          } catch (error) {
            alert(error.message, "warning");
          }
        }
      } else if (pago.tipo_pago === "Oxxo") {
        if (pago.correo === "") {
          alert(
            "Debe proporcionar un correo para el envio de su referencia",
            "warning"
          );
          return null;
        } else {
          const orden = {
            nombre: `${pago.nombre} ${pago.apellido}`,
            correo: pago.correo,
            telefono: pago.telefono,
            ciudad: pago.ciudad,
            coordenadas: pago.coordenadas,
            recoger_tienda: pago.recoger,
            direccion: pago.direccion,
            save_info: pago.save_info,
            metodo_pago: pago.tipo_pago,
            carrito: pago.carrito,
            total_pagar: pago.total_pagar,
            tienda_id: pago.tienda || "",
            costo_envio: pago.recoger ? 0 : pago.costo_envio,
          };
          return orden;
        }
      }
    }
  };

  const getPagoInfo = (costo_envio) => {
    if (cupon.data) {
      const { envio_gratis } = cupon.data;

      const total_pagar = envio_gratis
        ? cupon.total
        : cupon.total + costo_envio;

      return {
        subtotal: cupon.subtotal,
        total: total_pagar,
        descuento: cupon.descuento,
        envio_gratis,
        precio_original: cupon.subtotal + costo_envio,
      };
    } else {
      const subtotal = carrito.reduce((subtotal, item) => {
        return subtotal + item.cantidad * parseFloat(item.precio);
      }, 0);

      const total = subtotal + costo_envio;
      const descuento = 0;

      return {
        subtotal,
        descuento,
        total,
        envio_gratis: false,
        precio_original: total,
      };
    }
  };

  return {
    pagar,
    loading,
    getPagoInfo,
  };
};

export default Pago;
