import React, { useContext } from "react";
import { Navbar, Nav, Dropdown, Button } from "react-bootstrap";
import { logo_header } from "../constants/images";
import { Store } from "../context/RootContext";
import { landing_routes } from "../routes";
import { Link } from "react-router-dom";
import { Auth } from "../context/AuthContext";
import { FirebaseService } from "../services/firebase";
import { useHistory } from "react-router-dom";

const NavbarComp = () => {
  const { cantidadCarrito } = useContext(Store);
  const { authenticated } = useContext(Auth);
  const history = useHistory();

  const handleCarrito = () => {
    if (authenticated) {
      history.push("/carrito");
    } else {
      history.push("/login", { carrito: true });
    }
  };

  const handlerSignOut = async () => {
    FirebaseService.signOut();

    localStorage.removeItem("isAuth");

    window.location.href = window.location.origin + "/";
  };

  const LoginAccountOption = ({ showIcon = true }) => {
    if (authenticated) {
      return (
        <AccountMenu showIcon={showIcon} handlerSignOut={handlerSignOut} />
      );
    } else {
      return (
        <Link to="/login" className="nav-link mt-1">
          Iniciar sesión
        </Link>
      );
    }
  };

  return (
    <>
      <Navbar className="top-bar text-white">
        <Nav className="mr-auto mx-auto">Con Bee's VIBEE'S SANAMENTE</Nav>
        <Nav className="">
          <span
            className="icon-sn insta"
            onClick={() =>
              window.open(
                "https://www.instagram.com/tiendanaturalbees/",
                "_blank"
              )
            }
          ></span>
          <span
            className="icon-sn facebook"
            onClick={() =>
              window.open(
                "https://www.facebook.com/beestiendanatural",
                "_blank"
              )
            }
          ></span>
        </Nav>
      </Navbar>
      <Navbar className="navbar-background sticky-top" expand="lg">
        <Navbar.Brand onClick={() => history.push("/")}>
          <img
            src={logo_header}
            className="d-inline-block align-top mr-3"
            alt="React Bootstrap logo"
            style={{ width: 100, cursor: "pointer" }}
          />
        </Navbar.Brand>
        <Nav className="ml-auto d-sm-block d-md-none">
          <CarritoButton cantidad={cantidadCarrito} onClick={handleCarrito} />
        </Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto mx-auto">
            <div className="d-block d-md-none">
              <LoginAccountOption showIcon={false} />
            </div>
            {landing_routes.map((item) => (
              <Link className="nav-link" key={item.name} to={item.path}>
                {item.name}
              </Link>
            ))}
          </Nav>
          <Nav className="mr-5">
            <div className="d-none d-md-block">
              <LoginAccountOption showIcon />
            </div>
            <div className="d-none d-md-block">
              <CarritoButton
                cantidad={cantidadCarrito}
                onClick={handleCarrito}
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const CarritoButton = ({ cantidad, onClick = () => {} }) => {
  return (
    <Button className="mr-3" variant="text" onClick={onClick}>
      {cantidad > 0 && (
        <span
          className="badge badge-custom badge-pill bg-warning text-white"
          style={{
            position: "absolute",
            marginTop: 16,
            marginLeft: 8,
          }}
        >
          {cantidad}
        </span>
      )}
      <span className="align-middle">
        <svg
          width={28}
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
          fill={cantidad > 0 ? "#28a745" : "darkgrey"}
        >
          <g>
            <path d="M367.3,665.4h440.5c11.8,0,21.6-6.9,25.5-17.7l155-411.1c2.9-8.8,2-17.7-2.9-25.5c-4.9-7.8-13.7-11.8-22.6-11.8l-254.1-1c-2.9-1-4.9-1-7.8-1c-2.9,0-4.9,0-7.8,1l-460.2-2.9l-35.3-95.2c-2.9-6.9-7.8-12.8-15.7-15.7l-134.4-54c-13.7-5.9-30.4,1-35.3,15.7c-5.9,13.7,1,30.4,15.7,35.3l123.6,49.1l37.3,101.1c0,0,0,1,1,1l135.4,364l-59.8,159.9c-2.9,8.8-2,17.7,2.9,25.5c4.9,7.8,13.7,11.8,22.6,11.8h16.7c-19.6,18.6-32.4,45.1-32.4,74.6c0,56.9,46.1,103,103,103c56.9,0,103-46.1,103-103c0-29.4-12.8-55.9-32.4-74.6h216.8c-19.6,18.6-32.4,45.1-32.4,74.6c0,56.9,46.1,103,103,103c56.9,0,103-46.1,103-103c0-29.4-12.8-55.9-32.4-74.6h28.5c14.7,0,27.5-12.8,27.5-27.5c0-14.7-12.8-27.5-27.5-27.5H332l28.5-75.5C360.4,664.4,363.4,665.4,367.3,665.4z M375.1,914.6c-26.5,0-48.1-21.6-48.1-48.1c0-26.5,21.6-48.1,48.1-48.1c26.5,0,48.1,21.6,48.1,48.1C423.2,893,401.6,914.6,375.1,914.6z M732.3,914.6c-26.5,0-48.1-21.6-48.1-48.1c0-26.5,21.6-48.1,48.1-48.1c26.5,0,48.1,21.6,48.1,48.1C780.3,893,758.8,914.6,732.3,914.6z M673.4,384.8v90.3H512.5v-90.3H673.4L673.4,384.8z M512.5,329.8v-78.5l160.9,1v77.5H512.5z M839.2,474.1H727.4v-90.3h145.2L839.2,474.1z M673.4,529v80.5H512.5V529H673.4z M457.5,474.1H336.9l-33.4-90.3h154V474.1z M356.5,529h101.1v80.5h-70.6L356.5,529z M789.2,610.4h-60.8V530h91.2L789.2,610.4z M894.2,329.8H728.3v-77.5l194.3,1L894.2,329.8z M457.5,250.4v78.5H282.9l-29.4-80.5L457.5,250.4z" />
          </g>
        </svg>
      </span>
    </Button>
  );
};

const AccountMenu = ({ handlerSignOut, showIcon = true }) => {
  const history = useHistory();

  return (
    <Dropdown className="mt-2 mr-2">
      <Dropdown.Toggle variant="link" className="text-dark text-muted p-0">
        {showIcon ? (
          <i className="fas fa-user-circle fa-2x text-muted"></i>
        ) : (
          "Cuenta"
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {showIcon && (
          <Dropdown.Item onClick={() => history.push("/cuenta")}>
            Cuenta
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => history.push("/ordenes")}>
          Mis ordenes
        </Dropdown.Item>
        <Dropdown.Item onClick={() => history.push("/cuenta/likes")}>
          Me gustas
        </Dropdown.Item>
        <Dropdown.Item onClick={() => history.push("/cuenta/comentarios")}>
          Mis comentarios
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handlerSignOut}>
          <span>Cerrar sesión</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarComp;
