import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "../../context/AuthContext";
import Route from "./Route";

const AuthRoute = ({
  component: Component,
  title = "",
  registerView = true,
  ...rest
}) => {
  const { authenticated } = useContext(Auth);

  const HandleAuth = ({ props }) => {
    return authenticated ? <Component {...props} /> : <Redirect to="/" />;
  };

  return <Route title={title} Component={HandleAuth} {...rest} />;
};

export default AuthRoute;
