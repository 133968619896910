import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import "./Categorias.scss";
import Select from "react-select";

const Categorias = ({
  categorias,
  categoria = "",
  onSelected,
  onClear,
  defaultValue = null,
}) => {
  const handleOnChange = (selected) => {
    if (!selected) {
      onClear();
    } else {
      onSelected(selected.value);
    }
  };

  const options = useMemo(() => {
    return categorias.map((categoria) => ({
      value: categoria,
      label: categoria,
    }));
  }, [categorias]);

  const getDefaultValue = useMemo(() => {
    return options.find((option) => option.value === defaultValue);
  }, [options, defaultValue]);

  return (
    <>
      <Form.Group>
        <Form.Label className="font-weight-bold">Categorias</Form.Label>
        <Select
          styles={{
            control: (values) => ({
              ...values,
              borderColor: "#F3B91A",
              borderWidth: "1.5px",
              color: "darkgrey",
              fontWeight: 600,
              borderRadius: "10px",
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = "opacity 300ms";

              return { ...provided, opacity, transition };
            },
          }}
          isClearable
          noOptionsMessage={() => "No esta disponible esta categoria"}
          onChange={(value) => handleOnChange(value)}
          placeholder="Selecciona una categoria"
          defaultValue={getDefaultValue}
          options={options}
        />
      </Form.Group>
      {categoria && (
        <p className="badge bg-honey p-2">
          <i className="fas fa-filter mr-1"></i>
          Solo categoría: <b>{categoria}</b>
        </p>
      )}
    </>
  );
};

export default Categorias;
