export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        console.log(
          "API o permisos para la geolocalización fallaron, obtener ubicación default",
          err.message
        );
        // Hermosillo Coords
        resolve({
          lat: 29.1026,
          lng: -110.97732,
        });
      }
    );
  });
};
