import { useState } from "react";
import Swal from "sweetalert2";
import { createNotification } from "../libs/utils";
import { CodigoService } from "../services/CodigoService";

export const defaultCupon = {
  data: null,
  total: 0,
  subtotal: 0,
  descuento: 0,
};

export const useCupon = () => {
  const [loading, setLoading] = useState(false);
  const [cupon, setCupon] = useState({ ...defaultCupon });

  const verificarCodigo = async (
    codigo,
    productos,
    mostrarNotificacion = true
  ) => {
    try {
      if (!codigo) {
        return Swal.fire("Cupón invalido", "Debe ingresar un cupón", "error");
      }

      setLoading(true);

      const items = productos.map((producto) => {
        const { id, cantidad } = producto;

        return { id, cantidad };
      });

      const {
        data: { descuento, subtotal, total, code, cupon },
      } = await CodigoService.apply(codigo, items);

      const cuponValido = verifyCuponResponse(code, mostrarNotificacion);

      if (cuponValido) {
        setCupon({
          descuento,
          subtotal,
          total,
          data: cupon,
        });
      } else {
        setCupon({ ...defaultCupon });
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const { code } = err.response.data;
        handleCuponErrors(code);
      } else {
        createNotification("error", "Error al momento de verificar el cupón");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCuponErrors = (code) => {
    let message = "";
    switch (code) {
      case "cupon/not-found":
        message = "El cupón ingresado no existe";
        break;
      case "cupon/expired":
        message = "El cupón ya vencio";
        break;
      case "cupon/already-used":
        message = "Este cupón ya fue usado en una orden previa";
        break;

      case "cupon/not-first-purchase":
        message = "El cupón solo aplica para primeras compras";
        break;
      default:
        console.log("no cupon error handled", code);
    }

    Swal.fire("Cupón no aplica", message, "warning");
  };

  const verifyCuponResponse = (code, mostrarNotificacion) => {
    let cuponValido = false;
    let message = "";

    switch (code) {
      case "cupon/no-applies":
        message =
          "Ningúno de los productos del carrito aplica con la promoción";
        break;
      case "cupon/no-min-purchase":
        message = "El carrito no aplica con el mínimo de compra";
        break;
      case "cupon/applies":
        if (mostrarNotificacion) {
          createNotification("success", "Cupón aplicado con éxito");
        }
        cuponValido = true;
        break;
      default:
        console.log("code not handled");
    }

    if (!cuponValido) {
      Swal.fire("Cupón no aplica", message, "warning");
    }

    return cuponValido;
  };

  const clearCupon = () => {
    setCupon({ ...defaultCupon });
  };

  return {
    cupon,
    verificarCodigo,
    clearCupon,
    loading,
  };
};
