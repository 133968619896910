import React from "react";

import { ErrorMessage as FormErrorMessage } from "@hookform/error-message";
import { FormText } from "react-bootstrap";

const ErrorMessage = ({ name, errors }) => {
  return (
    <FormErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => (
        <FormText className="text-danger">
          <i className="fas fa-exclamation-triangle mr-1"></i>
          {message}
        </FormText>
      )}
    />
  );
};

export default ErrorMessage;
