import { useEffect, useState } from "react";
import { createNotification } from "../libs/utils";

export const useCarrito = ({ onChange = () => {} }) => {
  const [carrito, setCarrito] = useState(
    localStorage.hasOwnProperty("carrito")
      ? JSON.parse(localStorage.carrito)
      : []
  );

  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }, [carrito]);

  const agregarProducto = (producto, masUno = 0) => {
    const id = parseInt(producto.id);
    const cantidad = parseInt(producto.cantidad);

    let existe = carrito.findIndex((item) => parseInt(item.id) === id);

    if (existe === -1) {
      setCarrito(carrito.concat(producto));
    } else {
      let car = carrito.map((producto) => {
        if (parseInt(producto.id) === parseInt(id)) {
          if (masUno === 1) {
            //se verifique que la existencia del producto sea mayor al producto seleccionado
            if (producto.cantidad + 1 <= parseInt(producto.existencia)) {
              producto.cantidad += 1;
            }
          } else if (cantidad <= parseInt(producto.existencia)) {
            producto.cantidad = cantidad;
          }
        }
        return producto;
      });
      setCarrito(car);
      onChange(car);
    }
    createNotification("warning", "Carrito actualizado");
  };

  const eliminarProducto = (id) => {
    let index = carrito.findIndex((item) => item.id === id);
    if (carrito[index].cantidad > 1) {
      let car = carrito.map((producto) =>
        producto.id.toString() === id.toString()
          ? { ...producto, cantidad: producto.cantidad - 1 }
          : producto
      );
      setCarrito(car);
      onChange(car);
    } else if (carrito[index].cantidad === 1) {
      let filtro = carrito.filter((item) => item.id !== id);
      setCarrito(filtro);
      onChange(filtro);
    }
  };

  const clearCarrito = () => {
    setCarrito([]);
    onChange([]);
  };

  const sumar = async (id) => {
    const producto = carrito.find((producto) => producto.id === id);

    if (producto && producto.cantidad + 1 <= Number(producto.existencia)) {
      producto.cantidad += 1;
      onChange([...carrito]);
    }

    setCarrito([...carrito]);
  };

  const restar = async (id) => {
    const productoIdx = carrito.findIndex((producto) => producto.id === id);
    const producto = productoIdx >= 0 ? carrito[productoIdx] : null;

    if (producto) {
      producto.cantidad -= 1;

      if (producto.cantidad === 0) {
        carrito.splice(productoIdx, 1);
      }
    }

    setCarrito([...carrito]);
    onChange([...carrito]);
  };

  return {
    agregarProducto,
    eliminarProducto,
    clearCarrito,
    agregar: sumar,
    restar,
    carrito,
    cantidadCarrito: carrito.length,
  };
};
